export const GET_REPORT = "GET_REPORT";
export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS";

export const SET_REPORT = "SET_REPORT";
export const SET_REPORT_META = "SET_REPORT_META";

export const GET_REPORT_COMPANIES = "GET_REPORT_COMPANIES";
export const GET_REPORT_COMPANIES_SUCCESS = "GET_REPORT_COMPANIES_SUCCESS";

export const GET_SINGLE_REPORT = "GET_SINGLE_REPORT";
export const GET_SINGLE_REPORT_SUCCESS = "GET_SINGLE_REPORT_SUCCESS";

export const GET_COMPANY_PDF_REPORT = "GET_COMPANY_PDF_REPORT";
export const GET_COMPANY_PDF_REPORT_SUCCESS = "GET_COMPANY_PDF_REPORT_SUCCESS";

export const GET_REQUESTED_PDF_REPORT = "GET_REQUESTED_PDF_REPORT";
export const GET_REQUESTED_PDF_REPORT_SUCCESS = "GET_REQUESTED_PDF_REPORT_SUCCESS";

export const GET_DASHBOARD_REPORT = "GET_DASHBOARD_REPORT";
export const GET_DASHBOARD_REPORT_SUCCESS = "GET_DASHBOARD_REPORT_SUCCESS";

export const GET_REPORT_PAGINATION = "GET_REPORT_PAGINATION";
export const GET_REPORT_DATA_ERROR = "GET_REPORT_DATA_ERROR";
export const CLEAR_REPORT_STATE = "CLEAR_REPORT_STATE";

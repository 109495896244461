import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import {getContacts, createContacts, updateContacts, deleteContact} from '../../../library/http/backendHelpers';
import {CREATE_CONTACT, DELETE_CONTACTS, EDIT_CONTACT, GET_CONTACTS} from "./actionTypes";
import {
    createContactFail,
    createContactSuccess,
    dataError, deleteContactsSuccess, editContactFail,
    editContactSuccess,
    getContactsSuccess,
    setPagination
} from "./actions";
import {newRequestedContact} from "../contactRequested/actions";
import {setContactNr} from "../../companies/companiesList/actions";
import {deleteRequestSuccess} from "../../companies/companiesRequested/actions";


function* fetchContacts({payload: {company_kp, params}}) {
    try {
        const response = yield call(getContacts, company_kp, params);
        yield put(setPagination(response.meta))
        yield put(getContactsSuccess(response.data));
    }catch (error) {
        yield put(dataError(error))
    }
}

function* createContact({ payload: contact }) {
    try {
        const response = yield call(createContacts, contact);
        yield put(createContactSuccess(response.data));
        yield put(newRequestedContact(response.data))
        yield put(setContactNr())
    }catch (error) {
        yield put(createContactFail(error))
    }
}

function* updateContact({payload: {contact, contact_id}}){
    try {
        const response = yield call(updateContacts, contact, contact_id);
        yield put(editContactSuccess(response.data))
        yield put(newRequestedContact(response.data))
        yield put(setContactNr())
    } catch (error){
        yield put(editContactFail(error))
    }
}

function* deleteContactSaga({payload: { contact_id, reason }}) {
    try {

        const response = yield call(deleteContact, contact_id, {rejected_reason: reason});
        yield put(deleteContactsSuccess(response.data));
        yield put(newRequestedContact(response.data))
        yield put(setContactNr())
    }catch (error) {
        yield put(dataError(error))
    }
}


function* contactsSaga() {
    yield takeEvery(GET_CONTACTS, fetchContacts);
    yield takeEvery(CREATE_CONTACT, createContact);
    yield takeEvery(EDIT_CONTACT, updateContact);
    yield takeEvery(DELETE_CONTACTS, deleteContactSaga);
}

export default contactsSaga

import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import {GET_COMPANIES, GET_COMPANY, GET_COMPANY_HISTORY} from "./actionTypes";
import {getCompaniesSuccess, dataError, setPagination, getCompanySuccess, getCompanyHistorySuccess} from "./actions";
import {getCompanies, getCompany, getCompanyHistory} from '../../../library/http/backendHelpers';


function* fetchCompanies(params) {
    try {
        const response = yield call(getCompanies, params.filters);
        yield put(setPagination(response.meta))
        yield put(getCompaniesSuccess(response.data));
    }catch (error) {
        yield put(dataError(error))
    }
}

function* fetchCompany({payload: {company_kp}}) {
    try {
        const response = yield call(getCompany, company_kp);
        yield put(getCompanySuccess(response.data));
    }catch (error) {
        yield put(dataError(error))
    }
}

function* fetchCompanyHistory({payload: {company_kp}}) {
    try {
        const response = yield call(getCompanyHistory, company_kp);
        yield put(getCompanyHistorySuccess(response.data));
    }catch (error) {
        yield put(dataError(error))
    }
}

function* companiesSaga() {
    yield takeEvery(GET_COMPANIES, fetchCompanies);
    yield takeEvery(GET_COMPANY, fetchCompany);
    yield takeEvery(GET_COMPANY_HISTORY, fetchCompanyHistory);
  }

export default companiesSaga

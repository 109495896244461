import React, {useEffect, useState} from "react";
import {Divider, Table, Typography} from "antd";
import {useSelector} from "react-redux";


const ViewContactRequestChanged = () => {
    const {history} = useSelector(state => state.RequestedContacts)
    const {Text, Paragraph} = Typography;
    const userKeys = Object.keys(history.user_changed)
    const adminKeys = Object.keys(history.admin_changed)
    var filterKeys = userKeys.concat(adminKeys).filter((item, pos) => userKeys.concat(adminKeys).indexOf(item) === pos)

    const columns = [
        {
            title: 'Contact Name',
            dataIndex: 'contact_name',
            align: 'center',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            align: 'center'
        },
        {
            title: 'Contact Type',
            dataIndex: 'contact_type',
            align: 'center',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            align: 'center',
        },
        {
            title: 'Permission To Contact',
            dataIndex: 'permission_to_contact',
            align: 'center',
        },
    ];
    var filtered = columns.filter(function(item) {
        return filterKeys.indexOf(item.dataIndex) > -1;
    });
    filtered.unshift({title: 'Diff', dataIndex: 'diff', align: 'center', render(text, record) {
            return {
                props: {
                    style: { background:  "#ffffff"}
                },
                children: <Text strong>{text}</Text>
            };
        }}
    )
    useEffect(() => {
        if(Object.keys(history.original).length > 0) {
            history.original['diff'] = 'Original';
            history.user_changed['diff'] = 'User Change'
        } else {
            history.user_changed['diff'] = 'Original'
        }
        history.admin_changed['diff'] = 'Admin Change'
    }, [history])

    const data = (Object.keys(history.original).length > 0) ? [history.original, history.user_changed, history.admin_changed] : [history.user_changed, history.admin_changed]

    return (
        <>
            <Table
                columns={filtered}
                pagination={false}
                dataSource={data}
                bordered
            />
            <Divider orientation="left">User Notes</Divider>
            <Paragraph title="User Note">
                <blockquote>{history?.user_note}</blockquote>
            </Paragraph>
            <Divider orientation="left">Admin Note</Divider>
            <Paragraph title="Admin Note">
                <blockquote>{history?.fm_note}</blockquote>
            </Paragraph>

        </>
    );
}

export default ViewContactRequestChanged

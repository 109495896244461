import {
    CLEAR_COMPANIES_STATE,
    DATA_ERROR_COMPANIES,
    GET_COMPANIES,
    GET_COMPANIES_SUCCESS, GET_COMPANY, GET_COMPANY_HISTORY, GET_COMPANY_HISTORY_SUCCESS, GET_COMPANY_SUCCESS,
    GET_PAGINATION_COMPANIES, SET_AI_NR, SET_AI_NR_DECLINE,
    SET_COMPANY, SET_COMPANY_HISTORY, SET_CONTACT_NR, SET_CONTACT_NR_DECLINE
} from "./actionTypes";

export const getCompanies = (params) => ({
    type: GET_COMPANIES,
    filters: {...params}
});

export const getCompaniesSuccess = (data) => ({
    type: GET_COMPANIES_SUCCESS,
    payload: data
});

export const getCompany = (company_kp) => ({
    type: GET_COMPANY,
    payload: { company_kp }
});

export const getCompanySuccess = (data) => ({
    type: GET_COMPANY_SUCCESS,
    payload: data
});

export const getCompanyHistory = (company_kp) => ({
    type: GET_COMPANY_HISTORY,
    payload: { company_kp }
});

export const getCompanyHistorySuccess = (data) => ({
    type: GET_COMPANY_HISTORY_SUCCESS,
    payload: data
});

export const setCompany = company => ({
    type: SET_COMPANY,
    payload: company,
})

export const setCompanyHistory = history => ({
    type: SET_COMPANY_HISTORY,
    payload: history,
})
export const setContactNr = () => ({
    type: SET_CONTACT_NR,
})
export const setContactNrDecline = () => ({
    type: SET_CONTACT_NR_DECLINE,
})
export const setActiveIngredientNr = () => ({
    type: SET_AI_NR,
})
export const setActiveIngredientNrDecline = () => ({
    type: SET_AI_NR_DECLINE,
})
export const setPagination = pagination => ({
    type: GET_PAGINATION_COMPANIES,
    payload: pagination,
});

export const dataError = error => ({
    type: DATA_ERROR_COMPANIES,
    payload: error,
});

export const clearState = () => ({
    type: CLEAR_COMPANIES_STATE
});

import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, Row, Select, Popconfirm, Spin, Typography} from "antd";
import basicStyle from "@iso/assets/styles/constants";
import {useDispatch, useSelector} from "react-redux";
import {createCompaniesRequested} from "../../../../redux/companies/companiesRequested/actions";

const CompanyForm = () => {
    const {requestedCompany,formLoading} = useSelector(state => state.RequestedCompanies)
    const {colStyle} = basicStyle;
    const [buttonDisable, setButtonDisable] = useState(true);
    const {Text} = Typography
    const { TextArea } = Input;
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields()
    }, [requestedCompany])

    const onFinish = (data) => {
        let diff = Object.keys(data).reduce((diff, key) => {
            if (requestedCompany[key] === data[key]) return diff
            return {
                ...diff,
                [key]: data[key]
            }
        }, {})
        const formData = {
            address: (diff.address) ? diff.address : "",
            city: (diff.city) ? diff.city : "",
            company_name: (diff.company_name) ? diff.company_name : "",
            email: (diff.email) ? diff.email : "",
            fax: (diff.fax) ? diff.fax : "",
            notes: (diff.notes) ? diff.notes : "",
            second_address: (diff.second_address) ? diff.second_address : "",
            state_province: (diff.state_province) ? diff.state_province : "",
            tel: (diff.tel) ? diff.tel : "",
            website: (diff.website) ? diff.website : "",
            zip_postal: (diff.zip_postal) ? diff.zip_postal : "",
            formerly: (diff.formerly) ? diff.formerly : "",
            subsidiary_of: (diff.subsidiary_of) ? diff.subsidiary_of : ""
        }
       dispatch(createCompaniesRequested(formData, requestedCompany.identifier))
    }

    const onFormChange = (changedValues, allValues) => {
        let diff = Object.keys(allValues).reduce((diff, key) => {
            if (requestedCompany[key] === allValues[key]) return diff
            return {
                ...diff,
                [key]: allValues[key]
            }
        }, {})
        if (Object.keys(diff).length > 0){
            setButtonDisable(false)
        }else{
            setButtonDisable(true)
        }
    }

    return (
        <Spin spinning={formLoading}>
            <Form
                id="edit_company_form"
                name="edit_company_form"
                layout="vertical"
                form={form}
                style={{width: "100%"}}
                onFinish={onFinish}
                initialValues={requestedCompany}
                onValuesChange={onFormChange}
            >
                <Row gutter={24}>
                    <Col md={24} sm={12} xs={24} style={{...colStyle, marginBottom: 0}}>
                        <Row gutter={24}>
                            <Col md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Company Name"
                                    name="company_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input first name!",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Company Name" name="company_name"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col md={12} sm={12} xs={12}>
                                <Form.Item
                                    label="Address"
                                    name="address"
                                >
                                    <Input placeholder="Address One" name="address_one"/>
                                </Form.Item>
                            </Col>
                            <Col md={12} sm={12} xs={12}>
                                <Form.Item
                                    label="Address 2"
                                    name="second_address"
                                >
                                    <Input placeholder="Address Two" name="address_two"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col md={8} sm={8} xs={8}>
                                <Form.Item
                                    label="State/Province"
                                    name="state_province"
                                >
                                    <Input placeholder="State" name="state"/>
                                </Form.Item>
                            </Col>
                            <Col md={8} sm={8} xs={8}>
                                <Form.Item
                                    label="City"
                                    name="city"
                                >
                                    <Input placeholder="City" name="city"/>
                                </Form.Item>
                            </Col>
                            <Col md={8} sm={8} xs={24}>
                                <Form.Item
                                    label="Zip/Postal Code"
                                    name="zip_postal"
                                >
                                    <Input placeholder="Zip" name="zip"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col md={12} sm={12} xs={24}>
                                <Form.Item
                                    label="Tel"
                                    name="tel"
                                >
                                    <Input placeholder="Tel" name="tel"/>
                                </Form.Item>
                            </Col>
                            <Col md={12} sm={12} xs={24}>
                                <Form.Item
                                    label="Fax"
                                    name="fax"
                                >
                                    <Input placeholder="Fax" name="fax"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col md={12} sm={12} xs={12}>
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[
                                        {
                                            type: "email",
                                            message: "Please input a email address!",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Company email" name="email"/>
                                </Form.Item>
                            </Col>
                            <Col md={12} sm={12} xs={12}>
                                <Form.Item
                                    label="Website"
                                    name="website"
                                >
                                    <Input placeholder="Website" name="website"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col md={12} sm={12} xs={24}>
                                <Form.Item
                                    label="Previously Called"
                                    name="formerly"
                                >
                                    <Input placeholder="Previously Called" name="formerly"/>
                                </Form.Item>
                            </Col>
                            <Col md={12} sm={12} xs={24}>
                                <Form.Item
                                    label="Owned By"
                                    name="subsidiary_of"
                                >
                                    <Input placeholder="Owned By" name="subsidiary_of"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Notes"
                                    name="notes"
                                >
                                    <TextArea rows={3}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <div>
                            <Button form="edit_company_form" key="submit" htmlType="submit" type="primary"
                                    loading={formLoading} style={{float: "right"}}
                                    disabled={buttonDisable}>Send Request</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Spin>
    )

}

export default CompanyForm;

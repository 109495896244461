import { all } from 'redux-saga/effects';
import authSagas from '@iso/redux/auth/saga';
import menuSaga from '@iso/redux/menu/saga'
import routesSaga from '@iso/redux/routes/saga'
import usersSaga from "@iso/redux/users/saga";
import representingCompanies from "@iso/redux/representingCompanies/saga";
import companiesSaga from "@iso/redux/companies/companiesList/saga";
import contactsSaga from "@iso/redux/contacts/contactList/saga";
import activeIngredientsSaga from "@iso/redux/activeIngredients/activeIngredientsList/saga";
import requestedActiveIngredientsSaga from "@iso/redux/activeIngredients/activeIngredientsRequested/saga";
import requestedCompaniesSaga from "@iso/redux/companies/companiesRequested/saga";
import requestedContactsSaga from "@iso/redux/contacts/contactRequested/saga";
import profileSaga from "@iso/redux/profile/saga";
import reportSaga from "@iso/redux/report/saga";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    menuSaga(),
    profileSaga(),
    routesSaga(),
    usersSaga(),
    representingCompanies(),
    companiesSaga(),
    contactsSaga(),
    activeIngredientsSaga(),
    requestedCompaniesSaga(),
    requestedContactsSaga(),
    requestedActiveIngredientsSaga(),
    reportSaga()
  ]);
}

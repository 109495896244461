import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import {deleteContact, editRequestedContactHttps, getRequestedContacts,deleteRequestedContactHttps} from '../../../library/http/backendHelpers';
import {DELETE_REQUESTED_CONTACTS, EDIT_REQUESTED_CONTACT, GET_REQUESTED_CONTACTS} from "./actionTypes";
import {
    dataError,
    setPagination,
    getRequestedContactsSuccess,
    editRequestedContactSuccess,
    editRequestedContactFail, deleteRequestedContactsSuccess
} from "./actions";
import {deleteContactsSuccess} from "../contactList/actions";
import {setContactNr, setContactNrDecline} from "../../companies/companiesList/actions";

function* fetchRequestedContacts({payload: {company_kp, params}}) {
    try {
        const response = yield call(getRequestedContacts, company_kp, params);
        yield put(setPagination(response.meta))
        yield put(getRequestedContactsSuccess(response.data));
    }catch (error) {
        yield put(dataError(error))
    }
}

function* updateRequestedContact({ payload: { requested_contact, requested_contact_id }}) {
    try {
        const response = yield call(editRequestedContactHttps, requested_contact, requested_contact_id);
        yield put(editRequestedContactSuccess(response.data));
    }catch (error) {
        yield put(editRequestedContactFail(error))
    }
}

function* deleteRequestedContactSaga({payload: {contact_id}}) {
    try {
        const response = yield call(deleteRequestedContactHttps, contact_id);
        yield put(deleteRequestedContactsSuccess(contact_id));
        yield put(setContactNrDecline())
    }catch (error) {
        yield put(dataError(error))
    }
}

function* requestedContactsSaga() {
    yield takeEvery(GET_REQUESTED_CONTACTS, fetchRequestedContacts);
    yield takeEvery(EDIT_REQUESTED_CONTACT, updateRequestedContact);
    yield takeEvery(DELETE_REQUESTED_CONTACTS, deleteRequestedContactSaga);
}

export default requestedContactsSaga

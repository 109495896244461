import { call, takeEvery, put } from 'redux-saga/effects';
import {
    GET_COMPANY_PDF_REPORT,
    GET_REQUESTED_PDF_REPORT,
    GET_REPORT,
    GET_REPORT_COMPANIES, GET_SINGLE_REPORT, GET_DASHBOARD_REPORT,
} from "./actionTypes";
import {
    getReportSuccess,
    getReportCompaniesSuccess,
    setPagination,
    dataErrorReport,
    getSingleReportSuccess,
    setSingleReportMeta,
    getCompanyPdfReportSuccess,
    getRequestedPdfReportSuccess, getDashboardReportSuccess
} from "./actions";

import {reportRequest, reportCompanies, getSingleReport, getCompanyPdfReport, getRequestedPdfReport, getDashboardReport} from '../../library/http/backendHelpers'

function* fetchReport(params) {
    try {
        const response = yield call(reportRequest, params.filters);
        yield put(getReportSuccess(response.data));
        yield put(setPagination(response.meta))
    }catch (error) {
        yield put(dataErrorReport(error))
    }
}

function* fetchReportCompanies() {
    try {
        const response = yield call(reportCompanies);
        yield put(getReportCompaniesSuccess(response.data));
    }catch (error) {
        yield put(dataErrorReport(error))
    }
}

function* fetchSingleReport({payload: { identifier, request_for}}) {
    try {
        const response = yield call(getSingleReport, identifier, request_for);
        yield put(getSingleReportSuccess(response.data));
        yield put(setSingleReportMeta(response.meta))
    }catch (error) {
        yield put(dataErrorReport(error))
    }
}

function* fetchCompanyPdfReportData({payload: { company_identifier }}) {
    try {
        const response = yield call(getCompanyPdfReport, company_identifier);
        yield put(getCompanyPdfReportSuccess(response.data));
    }catch (error) {
        yield put(dataErrorReport(error))
    }
}

function* fetchRequestedPdfReportData({payload: { company_identifier }}) {
    try {
        const response = yield call(getRequestedPdfReport, company_identifier);
        yield put(getRequestedPdfReportSuccess(response.data));
    }catch (error) {
        yield put(dataErrorReport(error))
    }
}

function* fetchDashboardReport() {
    try {
        const response = yield call(getDashboardReport);
        yield put(getDashboardReportSuccess(response.data));
    }catch (error) {
        yield put(dataErrorReport(error))
    }
}

function* reportSaga() {
    yield takeEvery(GET_REPORT, fetchReport);
    yield takeEvery(GET_REPORT_COMPANIES, fetchReportCompanies);
    yield takeEvery(GET_SINGLE_REPORT, fetchSingleReport);
    yield takeEvery(GET_COMPANY_PDF_REPORT, fetchCompanyPdfReportData);
    yield takeEvery(GET_REQUESTED_PDF_REPORT, fetchRequestedPdfReportData);
    yield takeEvery(GET_DASHBOARD_REPORT, fetchDashboardReport);
}

export default reportSaga

export const GET_REQUESTED_COMPANIES = "GET_REQUESTED_COMPANIES";
export const GET_REQUESTED_COMPANIES_SUCCESS = "GET_REQUESTED_COMPANIES_SUCCESS";

export const SET_EDIT_COMPANY = "SET_EDIT_COMPANY";
export const SET_REQUEST_HISTORY = "SET_REQUEST_HISTORY";

export const CREATE_REQUESTED_COMPANIES = "CREATE_REQUESTED_COMPANIES";
export const CREATE_REQUESTED_COMPANIES_SUCCESS = "CREATE_REQUESTED_COMPANIES_SUCCESS";
export const CREATE_REQUESTED_COMPANIES_FAIL = "CREATE_REQUESTED_COMPANIES_FAIL";

export const EDIT_REQUESTED_COMPANIES = "EDIT_REQUESTED_COMPANIES";
export const EDIT_REQUESTED_COMPANIES_SUCCESS = "EDIT_REQUESTED_COMPANIES_SUCCESS";
export const EDIT_REQUESTED_COMPANIES_FAIL = "EDIT_REQUESTED_COMPANIES_FAIL";

export const DELETE_REQUESTED_COMPANIES = "DELETE_REQUESTED_COMPANIES";
export const DELETE_REQUESTED_COMPANIES_SUCCESS = "DELETE_REQUESTED_COMPANIES_SUCCESS";

export const GET_PAGINATION_REQUESTED_COMPANIES = "GET_PAGINATION_REQUESTED_COMPANIES";
export const DATA_ERROR_REQUESTED_COMPANIES = "DATA_ERROR_REQUESTED_COMPANIES";
export const CLEAR_REQUESTED_COMPANIES_STATE = "CLEAR_REQUESTED_COMPANIES_STATE";

import React, {useEffect} from "react";
import {Form, Input, Button, Spin} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {
    createActiveIngredientsComment,
} from "../../../../../redux/activeIngredients/activeIngredientsList/actions";


const CommentsForm = ({comment, identifier, type}) => {
    const { TextArea } = Input;
    const {formLoading} = useSelector(state => state.ActiveIngredients)
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    useEffect(() => {
        form.resetFields()
    }, [comment, identifier, type])

    const onFinish = (values) => {
        dispatch(createActiveIngredientsComment(identifier, values.comment))
    }

    return (
        <Spin spinning={formLoading}>
            <Form
                id="ai_comment_form"
                name="ai_comment_form"
                layout="vertical"
                form={form}
                style={{width: "100%"}}
                onFinish={onFinish}
                initialValues={{comment: comment}}
            >
                <Form.Item
                    label="Comment"
                    name="comment"
                >
                    <TextArea rows={4} placeholder="Comment" name="comment"/>
                </Form.Item>
            </Form>
        </Spin>
    );
}

export default CommentsForm

export const GET_ACTIVE_INGREDIENTS = "GET_ACTIVE_INGREDIENTS";
export const GET_ACTIVE_INGREDIENTS_SUCCESS = "GET_ACTIVE_INGREDIENTS_SUCCESS";

export const GET_SEARCH_ACTIVE_INGREDIENTS = "GET_SEARCH_ACTIVE_INGREDIENTS";
export const GET_SEARCH_ACTIVE_INGREDIENTS_SUCCESS = "GET_SEARCH_ACTIVE_INGREDIENTS_SUCCESS";

export const SET_ACTIVE_INGREDIENTS = "SET_ACTIVE_INGREDIENTS";

export const GET_AI_SYNONYMS = "GET_AI_SYNONYMS";
export const GET_AI_SYNONYMS_SUCCESS = "GET_AI_SYNONYMS_SUCCESS";
export const SET_AI_SYNONYMS = "SET_AI_SYNONYMS";

export const CREATE_ACTIVE_INGREDIENTS = "CREATE_ACTIVE_INGREDIENTS";
export const CREATE_ACTIVE_INGREDIENTS_SUCCESS = "CREATE_ACTIVE_INGREDIENTS_SUCCESS";
export const CREATE_ACTIVE_INGREDIENTS_FAIL = "CREATE_ACTIVE_INGREDIENTS_FAIL";

export const LINK_ACTIVE_INGREDIENTS = "LINK_ACTIVE_INGREDIENTS";
export const LINK_ACTIVE_INGREDIENTS_SUCCESS = "LINK_ACTIVE_INGREDIENTS_SUCCESS";
export const LINK_ACTIVE_INGREDIENTS_FAIL = "LINK_ACTIVE_INGREDIENTS_FAIL";

export const CREATE_ACTIVE_INGREDIENTS_COMMENTS = "CREATE_ACTIVE_INGREDIENTS_COMMENTS";
export const CREATE_ACTIVE_INGREDIENTS_COMMENTS_SUCCESS = "CREATE_ACTIVE_INGREDIENTS_COMMENTS_SUCCESS";
export const CREATE_ACTIVE_INGREDIENTS_COMMENTS_FAIL = "CREATE_ACTIVE_INGREDIENTS_COMMENTS_FAIL";

export const DELETE_ACTIVE_INGREDIENTS = "DELETE_ACTIVE_INGREDIENTS";
export const DELETE_ACTIVE_INGREDIENTS_SUCCESS = "DELETE_ACTIVE_INGREDIENTS_SUCCESS";

export const CLEAR_ACTIVE_INGREDIENTS= "CLEAR_ACTIVE_INGREDIENTS";

export const GET_PAGINATION_ACTIVE_INGREDIENTS = "GET_PAGINATION_ACTIVE_INGREDIENTS";
export const DATA_ERROR_ACTIVE_INGREDIENTS = "DATA_ERROR_ACTIVE_INGREDIENTS";
export const CLEAR_ACTIVE_INGREDIENTS_STATE = "CLEAR_ACTIVE_INGREDIENTS_STATE";

import {CLEAR_REP_STATE, CREATE_REP_COMPANY, CREATE_REP_COMPANY_ERROR, CREATE_REP_COMPANY_SUCCESS} from "./actionTypes";

export const createRepCompanies = (data) => {
    return {
        type: CREATE_REP_COMPANY,
        payload: { data },
    }
};

export const createRepCompaniesSuccess = response => {
    return {
        type: CREATE_REP_COMPANY_SUCCESS,
        payload: response
    }
};

export const createRepCompaniesError = error => {
    return {
        type: CREATE_REP_COMPANY_ERROR,
        payload: error
    }
};

export const clearState = () => ({
    type: CLEAR_REP_STATE
});

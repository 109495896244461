import {
    CLEAR_REQUESTED_COMPANIES_STATE,
    CREATE_REQUESTED_COMPANIES,
    CREATE_REQUESTED_COMPANIES_FAIL,
    CREATE_REQUESTED_COMPANIES_SUCCESS,
    DATA_ERROR_REQUESTED_COMPANIES, DELETE_REQUESTED_COMPANIES, DELETE_REQUESTED_COMPANIES_SUCCESS,
    EDIT_REQUESTED_COMPANIES,
    EDIT_REQUESTED_COMPANIES_FAIL,
    EDIT_REQUESTED_COMPANIES_SUCCESS,
    GET_PAGINATION_REQUESTED_COMPANIES,
    GET_REQUESTED_COMPANIES,
    GET_REQUESTED_COMPANIES_SUCCESS,
    SET_EDIT_COMPANY,
    SET_OLD_COMPANY_DATA,
    SET_REQUEST_HISTORY,
} from "./actionTypes";
import {CREATE_USER, CREATE_USER_FAIL, CREATE_USER_SUCCESS} from "../../users/actionTypes";

export const getRequestedCompanies = (params) => ({
    type: GET_REQUESTED_COMPANIES,
    filters: {...params}
});

export const getRequestedCompaniesSuccess = (data) => ({
    type: GET_REQUESTED_COMPANIES_SUCCESS,
    payload: data
});

export const setRequestedCompany = company => ({
    type: SET_EDIT_COMPANY,
    payload: company,
})

export const setRequestHistory = history => ({
    type: SET_REQUEST_HISTORY,
    payload: history,
})

export const createCompaniesRequested = (request, identifier) => ({
    type: CREATE_REQUESTED_COMPANIES,
    payload: {request, identifier}
});

export const createCompaniesRequestedSuccess = request => ({
    type: CREATE_REQUESTED_COMPANIES_SUCCESS,
    payload: request,
});

export const createCompaniesRequestedFail = error => ({
    type: CREATE_REQUESTED_COMPANIES_FAIL,
    payload: error,
})

export const editCompaniesRequested = (request, id) => ({
    type: EDIT_REQUESTED_COMPANIES,
    payload: {request, id}
});

export const editCompaniesRequestedSuccess = request => ({
    type: EDIT_REQUESTED_COMPANIES_SUCCESS,
    payload: request,
});

export const editCompaniesRequestedFail = error => ({
    type: EDIT_REQUESTED_COMPANIES_FAIL,
    payload: error,
})

export const deleteRequest = (id) => ({
    type: DELETE_REQUESTED_COMPANIES,
    payload: {id}
});

export const deleteRequestSuccess = (id) => ({
    type: DELETE_REQUESTED_COMPANIES_SUCCESS,
    payload: id
});

export const setPagination = pagination => ({
    type: GET_PAGINATION_REQUESTED_COMPANIES,
    payload: pagination,
});

export const dataError = error => ({
    type: DATA_ERROR_REQUESTED_COMPANIES,
    payload: error,
});

export const clearState = () => ({
    type: CLEAR_REQUESTED_COMPANIES_STATE
});

import {call, put, takeEvery} from "redux-saga/effects";
import {
    getUsers,
    createUsers,
    editUser,
    userPasswordChange,
    deleteUserRequest
} from '../../library/http/backendHelpers';

import {
    createUserFail,
    createUserSuccess,
    dataError,
    getUsersSuccess,
    updateUserFail,
    updateUserSuccess,
    changeUserPasswordSuccess,
    setPagination, deleteUserSuccess, resetUserPasswordSuccess
} from "./actions";

import {
    GET_USERS,
    CREATE_USER,
    UPDATE_USER,
    CHANGE_USER_PASSWORD, DELETE_USER, RESET_USER_PASSWORD,
} from "./actionTypes";

function* fetchUsers(params) {
    try {
        const response = yield call(getUsers, params.filters);
        yield put(setPagination(response.meta))
        yield put(getUsersSuccess(response.data));
    }catch (error) {
        yield put(dataError(error))
    }
}
function* createUser({ payload: user }) {
    try {
        const response = yield call(createUsers, user);
        yield put(createUserSuccess(response.data));
    }catch (error) {
        yield put(createUserFail(error))
    }
}

function* updateUser({ payload: {user, user_id}}) {
    try {
        const response = yield call(editUser, user, user_id);
        yield put(updateUserSuccess(response.data));
    }catch (error) {
        yield put(updateUserFail(error))
    }
}

function* deleteUser({ payload: {user_id}}) {
    try {
        const response = yield call(deleteUserRequest, user_id);
        yield put(deleteUserSuccess(user_id));
    }catch (error) {
        yield put(dataError(error))
    }
}

function* resetUserPassword({ payload: {user_id}}) {
    try {
        const response = yield call(userPasswordChange, user_id);
        yield put(resetUserPasswordSuccess());
    }catch (error) {
        yield put(dataError(error))
    }
}


function* usersSaga() {
    yield takeEvery(GET_USERS, fetchUsers);
    yield takeEvery(CREATE_USER, createUser);
    yield takeEvery(UPDATE_USER, updateUser);
    yield takeEvery(DELETE_USER, deleteUser);
    yield takeEvery(RESET_USER_PASSWORD, resetUserPassword);
}

export default usersSaga;

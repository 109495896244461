import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, Row, AutoComplete, Spin, Typography,  Modal } from "antd";
import { UserOutlined } from '@ant-design/icons';
import {useDispatch, useSelector} from "react-redux";
import basicStyle from "@iso/assets/styles/constants";
import {showNotification} from "../../../../../redux/notification/actions";
import {
    searchActiveIngredients,
    clearState,
    linkActiveIngredients
} from "../../../../../redux/activeIngredients/activeIngredientsList/actions";
import {closeModalMain, openModalMain} from "../../../../../redux/rootModal/actions";

const LinkActiveIngredientsForm = () => {
    const {colStyle} = basicStyle;
    const {Text} = Typography
    const {TextArea} = Input;
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const {company} = useSelector(state => state.Companies)
    const [options, setOptions] = useState([]);
    const [parentName, setParentName] = useState('');
    const [saltName, setSaltName] = useState('');
    const [selectedIdentifier, setSelectedIdentifier] = useState('');
    const {formLoading, searchLoading, searchedAi, success, error} = useSelector(state => state.ActiveIngredients)
    const { confirm } = Modal;
    const onFinish = (data) => {
        if (!selectedIdentifier){
            form.setFields([{
                name: 'linked_salt_name',
                errors: ['Please Select A Active Ingredient To link'],
            }]);
        }else {
            const formData = {
                salt_name: (data.linked_salt_name) ? data.linked_salt_name : '',
                ai_identifier: selectedIdentifier,
                notes: (data.notes) ? data.notes : '',
                web_scale: (data.web_scale) ? data.web_scale : '',
            }
            dispatch(linkActiveIngredients(formData,company.identifier))
        }
    }

    useEffect(() => {
        form.resetFields()
    }, [])

    useEffect(() => {
        const searchResult = [];
        searchedAi.map(item => {
            searchResult.push({
                identifier: item.identifier,
                value: item.salt,
                parent: (item.parent) ? item.parent : {}
            })
        })
        setOptions(searchResult)
    }, [dispatch, searchedAi])

    const notification = (type, message) => {
        dispatch(showNotification({ type: type, title: message }))
    };

    if (success !== "") {
        notification("success", success);
        form.resetFields()
        setOptions([])
        setParentName('')
        setSaltName('')
        setSelectedIdentifier('')
        dispatch(closeModalMain());
        dispatch(clearState());
    }
    if (error !== "") {
        if (error === 'Company already has this Active Ingredient' || error === 'This ingredient has already been added/requested'){
            Modal.error({
                title: error,
            });
            dispatch(clearState());
        }else {
            notification("error", error);
            dispatch(clearState());
        }
    }

    const search = (value) => {
        if (value.length >= 3 && company.identifier) {
            setOptions([])
            setSelectedIdentifier('')
            setParentName('')
            setSaltName('')
            dispatch(searchActiveIngredients(company.identifier, {query: value}))
        } else {
            setOptions([])
            setSelectedIdentifier('')
            setParentName('')
            setSaltName('')
        }
    }

    const onSelect = (value, option) => {
        setSaltName(option.value)
        if (Object.keys(option.parent).length > 0) {
            setSelectedIdentifier(option.parent.identifier)
            setParentName(option.parent.salt)
        }else {
            setSelectedIdentifier(option.identifier)
        }
    }

    const openAddAiModal = () => {
        let props = {
            modalVisibility: true,
            modalType: "ADD_AI_FORM",
            modalProps: {
                title: "Add New Active Ingredient ",
                width: 600,
                maskClosable: false,
                style: { top: "30px" },
                footer: "",
            },
        };
        dispatch(openModalMain(props));
    }


    return (
        <Spin spinning={formLoading}>
            <Form
                id="add_ai_form"
                name="add_ai_form"
                layout="vertical"
                form={form}
                style={{width: "100%"}}
                onFinish={onFinish}
                initialValues={[]}
            >
                <Row gutter={24}>
                    <Col md={24} sm={12} xs={24} style={{ marginBottom: 0}}>
                        <Row gutter={24}>
                            <Col md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Search Active Ingredient you want to add"
                                    name="linked_salt_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input active ingredient name!",
                                        },
                                    ]}
                                    style={(parentName) && {marginBottom: 0}}
                                >
                                    <AutoComplete
                                        dropdownClassName="certain-category-search-dropdown"
                                        dropdownMatchSelectWidth={500}
                                        style={{ width: "100%" }}
                                        options={options}
                                        onSelect={onSelect}
                                        onSearch={search}
                                        onClear={() => setSelectedIdentifier('')}
                                    >
                                        <Input.Search placeholder="Search Active Ingredient" style={{width: "100%"}} loading={searchLoading}/>
                                    </AutoComplete>
                                </Form.Item>

                                {(parentName) && <p style={{marginBottom: "10px", marginTop: "5px", color: "#389e0d"}}> {saltName} is listed in our database as {parentName} </p>}
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Scale"
                                    name="web_scale"
                                >
                                    <Input placeholder="Scale" name="scale"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Comments"
                                    name="notes"
                                >
                                    <TextArea rows={6}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <div>
                            <p style={{position: "absolute", width: "50%", bottom: "12px", fontSize: "12px"}}>
                                <a href="#" onClick={openAddAiModal}> Can’t find your active ingredient? Write it in here</a>
                            </p>
                            <Button form="add_ai_form" key="submit" htmlType="submit" type="primary" loading={formLoading} style={{float: "right"}} disabled={!selectedIdentifier}>Add Active Ingredient</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Spin>
    )
}

export default LinkActiveIngredientsForm;

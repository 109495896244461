import {
    GET_USERS,
    GET_USERS_SUCCESS,
    CLEAR_USERS_STATE,
    DATA_ERROR_USERS,
    CREATE_USER,
    CREATE_USER_SUCCESS,
    CREATE_USER_FAIL,
    SET_USER,
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAIL,
    CHANGE_USER_PASSWORD,
    CHANGE_USER_PASSWORD_SUCCESS,
    CHANGE_USER_PASSWORD_FAIL,
    GET_PAGINATION_USERS, DELETE_USER, DELETE_USER_SUCCESS, RESET_USER_PASSWORD, RESET_USER_PASSWORD_SUCCESS
} from "./actionTypes";


export const getUsers = (params) => ({
    type: GET_USERS,
    filters: {...params}
});

export const getUsersSuccess = (data) => ({
    type: GET_USERS_SUCCESS,
    payload: data
});

export const createUser = user => ({
    type: CREATE_USER,
    payload: user
});

export const createUserSuccess = user => ({
    type: CREATE_USER_SUCCESS,
    payload: user,
});

export const createUserFail = error => ({
    type: CREATE_USER_FAIL,
    payload: error,
})

export const setUser = user => ({
    type: SET_USER,
    payload: user,
})

export const updateUser = (user, user_id) => ({
    type: UPDATE_USER,
    payload: {user, user_id}
});

export const updateUserSuccess = user => ({
    type: UPDATE_USER_SUCCESS,
    payload: user,
});

export const updateUserFail = error => ({
    type: UPDATE_USER_FAIL,
    payload: error,
});

export const changeUserPassword = (password, user_id) => ({
    type: CHANGE_USER_PASSWORD,
    payload: {password, user_id}
});

export const changeUserPasswordSuccess = user => ({
    type: CHANGE_USER_PASSWORD_SUCCESS,
    payload: user,
});

export const changeUserPasswordFail = error => ({
    type: CHANGE_USER_PASSWORD_FAIL,
    payload: error,
});

export const deleteUser = (user_id) => ({
    type: DELETE_USER,
    payload: {user_id}
});

export const deleteUserSuccess = (id) => ({
    type: DELETE_USER_SUCCESS,
    payload: id
});

export const resetUserPassword = (user_id) => ({
    type: RESET_USER_PASSWORD,
    payload: {user_id}
});

export const resetUserPasswordSuccess = () => ({
    type: RESET_USER_PASSWORD_SUCCESS,
});

export const setPagination = pagination => ({
    type: GET_PAGINATION_USERS,
    payload: pagination,
});

export const dataError = error => ({
    type: DATA_ERROR_USERS,
    payload: error,
});

export const clearState = (state) => ({
    type: CLEAR_USERS_STATE,
    payload: state
});

export const GET_REQUESTED_ACTIVE_INGREDIENTS = "GET_REQUESTED_ACTIVE_INGREDIENTS";
export const GET_REQUESTED_ACTIVE_INGREDIENTS_SUCCESS = "GET_REQUESTED_ACTIVE_INGREDIENTS_SUCCESS";

export const SET_REQUESTED_ACTIVE_INGREDIENTS = "SET_REQUESTED_ACTIVE_INGREDIENTS";

export const NEW_REQUESTED_ACTIVE_INGREDIENTS = "NEW_REQUESTED_ACTIVE_INGREDIENTS";
export const SET_ACTIVE_INGREDIENTS_REQUEST_HISTORY = "SET_ACTIVE_INGREDIENTS_REQUEST_HISTORY";

export const GET_REQUESTED_AI_SYNONYMS = "GET_REQUESTED_AI_SYNONYMS";
export const GET_REQUESTED_AI_SYNONYMS_SUCCESS = "GET_REQUESTED_AI_SYNONYMS_SUCCESS";

export const EDIT_REQUESTED_ACTIVE_INGREDIENTS = "EDIT_REQUESTED_ACTIVE_INGREDIENTS";
export const EDIT_REQUESTED_ACTIVE_INGREDIENTS_SUCCESS = "EDIT_REQUESTED_ACTIVE_INGREDIENTS_SUCCESS";
export const EDIT_REQUESTED_ACTIVE_INGREDIENTS_FAIL = "EDIT_REQUESTED_ACTIVE_INGREDIENTS_FAIL";

export const EDIT_LINK_REQUESTED_ACTIVE_INGREDIENTS = "EDIT_LINK_REQUESTED_ACTIVE_INGREDIENTS";
export const EDIT_LINK_REQUESTED_ACTIVE_INGREDIENTS_SUCCESS = "EDIT_LINK_REQUESTED_ACTIVE_INGREDIENTS_SUCCESS";
export const EDIT_LINK_REQUESTED_ACTIVE_INGREDIENTS_FAIL = "EDIT_LINK_REQUESTED_ACTIVE_INGREDIENTS_FAIL";

export const DELETE_REQUESTED_ACTIVE_INGREDIENTS = "DELETE_REQUESTED_ACTIVE_INGREDIENTS";
export const DELETE_REQUESTED_ACTIVE_INGREDIENTS_SUCCESS = "DELETE_REQUESTED_ACTIVE_INGREDIENTS_SUCCESS"

export const CLEAR_REQUESTED_ACTIVE_INGREDIENTS= "CLEAR_REQUESTED_ACTIVE_INGREDIENTS";

export const GET_PAGINATION_REQUESTED_ACTIVE_INGREDIENTS = "GET_PAGINATION_REQUESTED_ACTIVE_INGREDIENTS";
export const DATA_ERROR_REQUESTED_ACTIVE_INGREDIENTS = "DATA_ERROR_REQUESTED_ACTIVE_INGREDIENTS";
export const CLEAR_REQUESTED_ACTIVE_INGREDIENTS_STATE = "CLEAR_REQUESTED_ACTIVE_INGREDIENTS_STATE";

import {
    CLEAR_CONTACTS,
    CLEAR_CONTACTS_STATE,
    CREATE_CONTACT,
    CREATE_CONTACT_FAIL,
    CREATE_CONTACT_SUCCESS,
    DATA_ERROR_CONTACTS,
    DELETE_CONTACTS,
    DELETE_CONTACTS_SUCCESS,
    EDIT_CONTACT,
    EDIT_CONTACT_FAIL,
    EDIT_CONTACT_SUCCESS,
    GET_CONTACTS,
    GET_CONTACTS_SUCCESS,
    GET_PAGINATION_CONTACTS,
    SET_CONTACT
} from "./actionTypes";
import {CREATE_USER, CREATE_USER_FAIL, CREATE_USER_SUCCESS} from "../../users/actionTypes";

const initialState = {
    contacts: [],
    contact: {},
    loading: false,
    formLoading: false,
    success: '',
    error: '',
    pagination: {
        current: 1,
        pageSize: 0,
        total: 0
    },
};

const Contacts = (state = initialState, action) => {
    switch (action.type) {
        case GET_CONTACTS:
            return {
                ...state,
                loading: true
            };
        case GET_CONTACTS_SUCCESS:
            return {
                ...state,
                contacts: action.payload,
                loading: false
            };
        case SET_CONTACT:
            return {
                ...state,
                contact: action.payload,
            };
        case CREATE_CONTACT:
            return {
                ...state,
                formLoading: true
            };
        case CREATE_CONTACT_SUCCESS:
            return {
                ...state,
                success: 'Request to create contact was successfully sent',
                formLoading: false
            };
        case CREATE_CONTACT_FAIL:
            return {
                ...state,
                error: action.payload,
                formLoading: false,
            };
        case EDIT_CONTACT:
            return {
                ...state,
                formLoading: true
            };
        case EDIT_CONTACT_SUCCESS:
            return {
                ...state,
                success: 'Request to update contact was successfully sent',
                formLoading: false
            };
        case EDIT_CONTACT_FAIL:
            return {
                ...state,
                error: action.payload,
                formLoading: false
            };
        case DELETE_CONTACTS:
            return {
                ...state,
                loading: true
            };
        case DELETE_CONTACTS_SUCCESS:
            return {
                ...state,
                success: 'Request to delete contact was successfully sent',
                loading: false
            };
        case DATA_ERROR_CONTACTS:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case GET_PAGINATION_CONTACTS:
            return {
                ...state,
                pagination: {
                    current: action.payload.current_page,
                    pageSize: action.payload.per_page,
                    total: action.payload.total
                },
            };
        case CLEAR_CONTACTS:
            return {
                ...state,
                contacts: []
            };
        case CLEAR_CONTACTS_STATE:
            return {
                ...state,
                error: '',
                success: '',
                loading: false,
                formLoading: false
            };
        default:
            return state;
    }
}

export default Contacts;

import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import {
    CREATE_REQUESTED_COMPANIES,
    DELETE_REQUESTED_COMPANIES,
    EDIT_REQUESTED_COMPANIES,
    GET_REQUESTED_COMPANIES
} from "./actionTypes";
import {
    getRequestedCompaniesSuccess,
    createCompaniesRequestedSuccess,
    createCompaniesRequestedFail,
    dataError,
    setPagination,
    editCompaniesRequestedSuccess, editCompaniesRequestedFail, deleteRequestSuccess
} from "./actions";
import {getRequestedCompanies, createCompanyRequest, updateCompanyRequest, deleteRequest} from '../../../library/http/backendHelpers';


function* fetchRequestedCompanies(params) {
    try {
        const response = yield call(getRequestedCompanies, params.filters);
        console.log(response)
        yield put(setPagination(response.meta))
        yield put(getRequestedCompaniesSuccess(response.data));
    }catch (error) {
        yield put(dataError(error))
    }
}

function* createCompanyRequestSaga({payload: {request, identifier}}) {
   try {
       const response = yield call(createCompanyRequest, request, identifier);
       yield put(createCompaniesRequestedSuccess(response.data));
   }catch (error) {
       yield put(createCompaniesRequestedFail(error))
   }
}

function* editCompanyRequestSaga({payload: {request, id}}) {
    try {
        const response = yield call(updateCompanyRequest, request, id);
        yield put(editCompaniesRequestedSuccess(response.data));
    }catch (error) {
        yield put(editCompaniesRequestedFail(error))
    }
}

function* deleteRequestSaga({payload: {id}}) {
    try {
        const response = yield call(deleteRequest, id);
        yield put(deleteRequestSuccess(id));
    }catch (error) {
        yield put(dataError(error))
    }
}


function* requestedCompaniesSaga() {
    yield takeEvery(GET_REQUESTED_COMPANIES, fetchRequestedCompanies);
    yield takeEvery(CREATE_REQUESTED_COMPANIES, createCompanyRequestSaga);
    yield takeEvery(EDIT_REQUESTED_COMPANIES, editCompanyRequestSaga);
    yield takeEvery(DELETE_REQUESTED_COMPANIES, deleteRequestSaga);
  }

export default requestedCompaniesSaga

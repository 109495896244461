import React, {useEffect, useState} from "react";
import {Divider, Table, Typography} from "antd";
import {useSelector} from "react-redux";

const ViewCompanyRequest = () => {
    const {history} = useSelector(state => state.RequestedCompanies)
    const { Title, Paragraph, Text, Link } = Typography;
    const [data, setData] = useState([]);
    const columns = [
        {
            title: 'Company Name',
            dataIndex: 'company_name',
            align: 'center'
        },
        {
            title: 'Address',
            dataIndex: 'address',
            align: 'center'
        },
        {
            title: 'Second Address',
            dataIndex: 'second_address',
            align: 'center'
        },
        {
            title: 'City',
            dataIndex: 'city',
            align: 'center'
        },
        {
            title: 'State Province',
            dataIndex: 'state_province',
            align: 'center'
        },
        {
            title: 'Tel',
            dataIndex: 'tel',
            align: 'center'
        },
        {
            title: 'Fax',
            dataIndex: 'fax',
            align: 'center'
        },
        {
            title: 'Zip Postal',
            dataIndex: 'zip_postal',
            align: 'center'
        },
        {
            title: 'Website',
            dataIndex: 'website',
            align: 'center'
        },
        {
            title: 'Previously Called',
            dataIndex: 'formerly',
            align: 'center'
        },
        {
            title: 'Owned By',
            dataIndex: 'subsidiary_of',
            align: 'center'
        },
    ];

    var filtered = columns.filter(function(item) {
        return Object.keys(history?.user_changed).indexOf(item.dataIndex) > -1;
    });
    filtered.unshift({title: 'Diff', dataIndex: 'diff', align: 'center'})
    history.original["diff"] = "old";
    history.user_changed["diff"] = "new";
    return (
        <>
        <Table
            columns={filtered}
            dataSource={[history.original, history.user_changed]}
            pagination={false}
            rowClassName={(record, index) => (index === 0 ? "diff-old" : "diff-new")}
            bordered
        />
            <Divider orientation="left">Note</Divider>
            <Paragraph>
                <blockquote>{history?.user_note}</blockquote>
            </Paragraph>

        </>
    );
}

export default ViewCompanyRequest;

import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import {
    dataError, deleteAiRequestSuccess, editAIRequestedFail,
    editAIRequestedSuccess, editLinkAIRequestedFail, editLinkAIRequestedSuccess,
    getRequestedActiveIngredientsSuccess,
    setPagination
} from "./actions";
import { getRequestedActiveIngredients, updateRequestActiveIngredient, updateRequestLinkedActiveIngredient, deleteRequestedActiveIngredientHttps} from '../../../library/http/backendHelpers'
import {
    DELETE_REQUESTED_ACTIVE_INGREDIENTS,
    EDIT_LINK_REQUESTED_ACTIVE_INGREDIENTS,
    EDIT_REQUESTED_ACTIVE_INGREDIENTS,
    GET_REQUESTED_ACTIVE_INGREDIENTS
} from "./actionTypes";
import {setActiveIngredientNrDecline} from "../../companies/companiesList/actions";


function* fetchRequestedActiveIngredients({payload: {company_identifier, params}}) {
    try {
        const response = yield call(getRequestedActiveIngredients, company_identifier, params);
        yield put(setPagination(response.meta))
        yield put(getRequestedActiveIngredientsSuccess(response.data));
    }catch (error) {
        yield put(dataError(error))
    }
}

function* editAiRequestSaga({payload: {request, id}}) {
    try {
        const response = yield call(updateRequestActiveIngredient, request, id);
        yield put(editAIRequestedSuccess(response.data));
    }catch (error) {
        yield put(editAIRequestedFail(error))
    }
}

function* editAiLinkedRequestSaga({payload: {request, id}}) {
    try {
        const response = yield call(updateRequestLinkedActiveIngredient, request, id);
        yield put(editLinkAIRequestedSuccess(response.data));
    }catch (error) {
        yield put(editLinkAIRequestedFail(error))
    }
}

function* deleteAiRequestSaga({payload: {id}}) {
    try {
        const response = yield call(deleteRequestedActiveIngredientHttps, id);
        yield put(deleteAiRequestSuccess(id));
        yield put(setActiveIngredientNrDecline());
    }catch (error) {
        yield put(dataError(error))
    }
}

function* requestedActiveIngredientsSaga() {
    yield takeEvery(GET_REQUESTED_ACTIVE_INGREDIENTS, fetchRequestedActiveIngredients);
    yield takeEvery(EDIT_REQUESTED_ACTIVE_INGREDIENTS, editAiRequestSaga);
    yield takeEvery(EDIT_LINK_REQUESTED_ACTIVE_INGREDIENTS, editAiLinkedRequestSaga);
    yield takeEvery(DELETE_REQUESTED_ACTIVE_INGREDIENTS, deleteAiRequestSaga);
}

export default requestedActiveIngredientsSaga

import React, {useEffect, useState} from "react";
import {Divider, Table, Tag, Tooltip, Typography} from "antd";
import {useSelector} from "react-redux";

const ViewContactRequest = () => {
    const {history} = useSelector(state => state.RequestedContacts)
    const { Paragraph} = Typography;

    const columns = [
        {
            title: 'Contact Name',
            dataIndex: 'contact_name',
            align: 'center',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            align: 'center'
        },
        {
            title: 'Contact Type',
            dataIndex: 'contact_type',
            align: 'center',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            align: 'center',
        },
        {
            title: 'Permission To Contact',
            dataIndex: 'permission_to_contact',
            align: 'center',
        },
    ];
   var filtered = columns.filter(function(item) {
       return Object.keys(history?.user_changed).indexOf(item.dataIndex) > -1;
   });

   filtered.unshift({title: 'Diff', dataIndex: 'diff', align: 'center'})
   useEffect(() => {
       if(Object.keys(history.original).length > 0){
           history.original["diff"] = "old"
       }
       history.user_changed["diff"] = "new";
   }, [history])
    return (
        <>
            <Table
                columns={filtered}
                dataSource={(Object.keys(history.original).length > 0) ? [history.original, history.user_changed] : [history.user_changed]}
                pagination={false}
                rowClassName={(record, index) => (Object.keys(history.original).length > 0) ? (index === 0 ? "diff-old" : "diff-new") : ''}
                bordered
            />
            <Divider orientation="left">Note</Divider>
            <Paragraph>
                <blockquote>{history?.user_note}</blockquote>
            </Paragraph>

        </>
    );
}

export default ViewContactRequest;

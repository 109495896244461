import React, {useEffect} from "react";
import {Button, Col, Form, Input, Modal, Row, Spin, Typography} from "antd";
import {useDispatch, useSelector} from "react-redux";
import basicStyle from "@iso/assets/styles/constants";
import {showNotification} from "../../../../../../redux/notification/actions";
import {closeModalMain} from "../../../../../../redux/rootModal/actions";
import {editAIRequested, clearState} from "../../../../../../redux/activeIngredients/activeIngredientsRequested/actions";

const RequestedActiveIngredientForm = () => {
    const {colStyle} = basicStyle;
    const {Text} = Typography
    const {TextArea} = Input;
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const {company} = useSelector(state => state.Companies)
    const {requestedActiveIngredient, success, error, formLoading} = useSelector(state => state.RequestedActiveIngredients)

    useEffect(() => {
        form.resetFields()
    }, [requestedActiveIngredient])

    const onFinish = (data) => {
        if (company?.identifier) {
            const formData = {
                salt_name: (data.salt_name) ? data.salt_name : '',
                notes: (data.notes) ? data.notes : '',
                web_scale: (data.web_scale) ? data.web_scale : '',
            }
            dispatch(editAIRequested(formData, requestedActiveIngredient.identifier))
        }
    }

    const notification = (type, message) => {
        dispatch(showNotification({ type: type, title: message }))
    };

    if (success !== "") {
        notification("success", success);
        dispatch(closeModalMain());
        dispatch(clearState());
    }
    if (error !== "") {
        if (error === 'Company already has this Active Ingredient' || error === 'This ingredient has already been added/requested'){
            Modal.error({
                title: error,
            });
            dispatch(clearState());
        }else {
            notification("error", error);
            dispatch(clearState());
        }
    }

    return (
        <Spin spinning={formLoading}>
            <Form
                id="edit_ai_form"
                name="edit_ai_form"
                layout="vertical"
                form={form}
                style={{width: "100%"}}
                onFinish={onFinish}
                initialValues={requestedActiveIngredient}
            >
                <Row gutter={24}>
                    <Col md={24} sm={12} xs={24} style={{...colStyle, marginBottom: 0}}>
                        <Row gutter={24}>
                            <Col md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Active Ingredient Name"
                                    name="salt_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input active ingredient name!",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Active Ingredient Name" name="salt_name"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Scale"
                                    name="web_scale"
                                >
                                    <Input placeholder="Scale" name="scale"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col md={24} sm={24} xs={24} style={colStyle}>
                                <Form.Item
                                    label="Comments"
                                    name="notes"
                                >
                                    <TextArea rows={6}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <div>
                            <Button form="edit_ai_form" key="submit" htmlType="submit" type="primary" loading={formLoading} style={{float: "right"}}>Save Changes</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Spin>
    )
}

export default RequestedActiveIngredientForm;

import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import Menu from '@iso/redux/menu/reducer';
import Routes from '@iso/redux/routes/reducer';
import rootModal from '@iso/redux/rootModal/reducer';
import notification from '@iso/redux/notification/reducer';
import Users from "@iso/redux/users/reducer";
import RepresentingCompanies from "@iso/redux/representingCompanies/reducer";
import Companies from "@iso/redux/companies/companiesList/reducer";
import Contacts from "@iso/redux/contacts/contactList/reducer";
import ActiveIngredients from "@iso/redux/activeIngredients/activeIngredientsList/reducer";
import RequestedActiveIngredients from "@iso/redux/activeIngredients/activeIngredientsRequested/reducer";
import RequestedCompanies from "@iso/redux/companies/companiesRequested/reducer";
import RequestedContacts from "@iso/redux/contacts/contactRequested/reducer";
import Profile from "@iso/redux/profile/reducer";
import Report from "@iso/redux/report/reducer";

export default combineReducers({
  App,
  LanguageSwitcher,
  ThemeSwitcher,
  Auth,
  Profile,
  Menu,
  Routes,
  rootModal,
  notification,
  Users,
  RepresentingCompanies,
  Companies,
  Contacts,
  ActiveIngredients,
  RequestedActiveIngredients,
  RequestedCompanies,
  RequestedContacts,
  Report
});

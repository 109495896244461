import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGOUT,
    CHECK_AUTHORIZATION,
    SET_AUTH_STATE,
    SET_AUTH_SUCCESS,
    SET_AUTH_FAIL,
    REGISTER_REQUEST,
    REGISTER_SUCCESS,
    REGISTER_ERROR,
    REGISTER_VALIDATION_ERROR,
    CLEAR_AUTH_STATE,
    VERIFY_EMAIL,
    VERIFY_EMAIL_SUCCESS,
    VERIFY_EMAIL_ERROR,
    RESEND_EMAIL,
    RESEND_EMAIL_SUCCESS,
    RESEND_EMAIL_ERROR,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR, PASSWORD_RESET, PASSWORD_RESET_SUCCESS, PASSWORD_RESET_ERROR
} from "./actionTypes";
import {CONFIRM_EMAIL, CONFIRM_EMAIL_ERROR, CONFIRM_EMAIL_SUCCESS} from "../confirmEmail/actionTypes";

export const login = (user, history) => {
    return {
        type: LOGIN_REQUEST,
        payload: { user, history },
    }
};

export const loginSuccess = response => {
    return {
        type: LOGIN_SUCCESS,
        payload: response
    }
};

export const loginError = error => {
    return {
        type: LOGIN_ERROR,
        payload: error
    }
};

export const register = (user, history) => {
    return {
        type: REGISTER_REQUEST,
        payload: { user, history },
    }
};

export const registerSuccess = response => {
    return {
        type: REGISTER_SUCCESS,
        payload: response
    }
};

export const registerError = error => {
    return {
        type: REGISTER_ERROR,
        payload: error
    }
};

export const registerValidationError = error => {
    return {
        type: REGISTER_VALIDATION_ERROR,
        payload: error
    }
};

export const logout = (history) => {
  return {
    type: LOGOUT,
    payload: { history }
  }
};

export const checkAuthorization = () => {
  return {
    type: CHECK_AUTHORIZATION
  }
};

export const setAuthState = () => {
    return {
        type: SET_AUTH_STATE
    }
};
export const setAuthStateSuccess = (data) => {
    return {
        type: SET_AUTH_SUCCESS,
        payload: data
    }
};

export const setAuthStateFail = error => {
    return {
        type: SET_AUTH_FAIL,
        payload: error
    }
};

export const clearState = (state) => ({
    type: CLEAR_AUTH_STATE,
    payload: state
});

export const confirmEmail = (user_id, hash, history) => {
    return {
        type: VERIFY_EMAIL,
        payload: { user_id, hash, history },
    }
};

export const confirmEmailSuccess = response => {
    return {
        type: VERIFY_EMAIL_SUCCESS,
        payload: response
    }
};

export const confirmEmailError = error => {
    return {
        type: VERIFY_EMAIL_ERROR,
        payload: error
    }
};

export const resendVerificationEmail = () => {
    return {
        type: RESEND_EMAIL,
    }
};

export const resendVerificationEmailSuccess = response => {
    return {
        type: RESEND_EMAIL_SUCCESS,
        payload: response
    }
};

export const resendVerificationEmailError = error => {
    return {
        type: RESEND_EMAIL_ERROR,
        payload: error
    }
};

export const resetPassword = (password, history) => {
    return {
        type: RESET_PASSWORD,
        payload: { password, history },
    }
};

export const resetPasswordSuccess = response => {
    return {
        type: RESET_PASSWORD_SUCCESS,
        payload: response
    }
};

export const resetPasswordError = error => {
    return {
        type: RESET_PASSWORD_ERROR,
        payload: error
    }
};

export const forgotPassword = (email, history) => {
    return {
        type: FORGOT_PASSWORD,
        payload: { email, history },
    }
};

export const forgotPasswordSuccess = response => {
    return {
        type: FORGOT_PASSWORD_SUCCESS,
        payload: response
    }
};

export const forgotPasswordError = error => {
    return {
        type: FORGOT_PASSWORD_ERROR,
        payload: error
    }
};

export const passwordReset = (data) => {
    return {
        type: PASSWORD_RESET,
        payload: { data },
    }
};

export const passwordResetSuccess = response => {
    return {
        type: PASSWORD_RESET_SUCCESS,
        payload: response
    }
};

export const passwordResetError = error => {
    return {
        type: PASSWORD_RESET_ERROR,
        payload: error
    }
};

import {
    CLEAR_REQUESTED_CONTACTS_STATE,
    DATA_ERROR_REQUESTED_CONTACTS,
    GET_REQUESTED_CONTACTS,
    GET_REQUESTED_CONTACTS_SUCCESS,
    GET_PAGINATION_REQUESTED_CONTACTS,
    NEW_REQUESTED_CONTACT,
    SET_CONTACT_REQUEST_HISTORY,
    SET_CONTACT_REQUEST,
    EDIT_REQUESTED_CONTACT,
    EDIT_REQUESTED_CONTACT_SUCCESS,
    EDIT_REQUESTED_CONTACT_FAIL, DELETE_REQUESTED_CONTACTS, DELETE_REQUESTED_CONTACTS_SUCCESS
} from "./actionTypes";


export const getRequestedContacts = (company_kp, params) => ({
    type: GET_REQUESTED_CONTACTS,
    payload: { company_kp, params }
});

export const getRequestedContactsSuccess = (data) => ({
    type: GET_REQUESTED_CONTACTS_SUCCESS,
    payload: data
});

export const newRequestedContact = (data) => ({
    type: NEW_REQUESTED_CONTACT,
    payload: data
});

export const setContactRequestHistory = (history) => ({
    type: SET_CONTACT_REQUEST_HISTORY,
    payload: history
})

export const setContactRequest = (data) => ({
    type: SET_CONTACT_REQUEST,
    payload: data
})

export const editRequestedContact = (requested_contact, requested_contact_id) => ({
    type: EDIT_REQUESTED_CONTACT,
    payload: { requested_contact, requested_contact_id }
});

export const editRequestedContactSuccess = (requested_contact) => ({
    type: EDIT_REQUESTED_CONTACT_SUCCESS,
    payload: requested_contact
});

export const editRequestedContactFail = (error) => ({
    type: EDIT_REQUESTED_CONTACT_FAIL,
    payload: error
});

export const deleteRequestedContacts = (contact_id) => ({
    type: DELETE_REQUESTED_CONTACTS,
    payload: { contact_id }
});

export const deleteRequestedContactsSuccess = (data) => ({
    type: DELETE_REQUESTED_CONTACTS_SUCCESS,
    payload: data
});

export const setPagination = pagination => ({
    type: GET_PAGINATION_REQUESTED_CONTACTS,
    payload: pagination,
});

export const dataError = error => ({
    type: DATA_ERROR_REQUESTED_CONTACTS,
    payload: error,
});

export const clearState = () => ({
    type: CLEAR_REQUESTED_CONTACTS_STATE
});

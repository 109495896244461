import React from 'react';
import {Provider, useDispatch} from 'react-redux';
import GlobalStyles from '@iso/assets/styles/globalStyle';
import { store } from './redux/store';
import Boot from './redux/boot';
import Routes from './router';
import AppProvider from './AppProvider';
import RootModal  from '@iso/pages/Modal/RootModal';
import NotificationWindow from '@iso/components/Notifications/NotificationWindow';
import {Button} from "antd";
import {openModalMain} from "./redux/rootModal/actions";
import CompaniesRepresenting from "./pages/CompaniesRepresenting/CompaniesRepresenting";


const App = () => {
    return (
  <Provider store={store}>
    <AppProvider>
      <>
        <GlobalStyles />
        <RootModal />
        <NotificationWindow />
        <Routes />
          <CompaniesRepresenting/>
      </>
    </AppProvider>
  </Provider>
)};
Boot()
  .then(() => App())
  .catch(error => console.error(error));

export default App;

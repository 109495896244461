export const GET_REQUESTED_CONTACTS = "GET_REQUESTED_CONTACTS";
export const GET_REQUESTED_CONTACTS_SUCCESS = "GET_REQUESTED_CONTACTS_SUCCESS";

export const NEW_REQUESTED_CONTACT = "NEW_REQUESTED_CONTACT";
export const SET_CONTACT_REQUEST = "SET_CONTACT_REQUEST";
export const SET_CONTACT_REQUEST_HISTORY = "SET_CONTACT_REQUEST_HISTORY";

export const EDIT_REQUESTED_CONTACT = "EDIT_REQUESTED_CONTACT";
export const EDIT_REQUESTED_CONTACT_SUCCESS = "EDIT_REQUESTED_CONTACT_SUCCESS";
export const EDIT_REQUESTED_CONTACT_FAIL = "EDIT_REQUESTED_CONTACT_FAIL";

export const DELETE_REQUESTED_CONTACTS = "DELETE_REQUESTED_CONTACTS";
export const DELETE_REQUESTED_CONTACTS_SUCCESS = "DELETE_REQUESTED_CONTACTS_SUCCESS";

export const GET_PAGINATION_REQUESTED_CONTACTS = "GET_PAGINATION_REQUESTED_CONTACTS";
export const DATA_ERROR_REQUESTED_CONTACTS = "DATA_ERROR_REQUESTED_CONTACTS";
export const CLEAR_REQUESTED_CONTACTS_STATE = "CLEAR_CONTACTS_STATE";

import React, {useEffect, useState} from "react";
import {AutoComplete, Button, Col, Form, Input, Modal, Row, Spin, Typography} from "antd";
import {useDispatch, useSelector} from "react-redux";
import basicStyle from "@iso/assets/styles/constants";
import {
    linkActiveIngredients,
    searchActiveIngredients
} from "../../../../../../redux/activeIngredients/activeIngredientsList/actions";
import {
    clearState,
    editLinkAIRequested
} from "../../../../../../redux/activeIngredients/activeIngredientsRequested/actions";
import {showNotification} from "../../../../../../redux/notification/actions";
import {closeModalMain} from "../../../../../../redux/rootModal/actions";


const RequestedActiveIngredientLinkForm = () => {
    const {colStyle} = basicStyle;
    const {Text} = Typography
    const {TextArea} = Input;
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const {company} = useSelector(state => state.Companies)
    const {requestedActiveIngredient, success, error} = useSelector(state => state.RequestedActiveIngredients)
    const [selectedIdentifier, setSelectedIdentifier] = useState((Object.keys(requestedActiveIngredient).length > 0) ? requestedActiveIngredient?.ai_identifier: '');
    const [options, setOptions] = useState([]);
    const [parentName, setParentName] = useState('');
    const [saltName, setSaltName] = useState('');
    const {formLoading, searchedAi} = useSelector(state => state.ActiveIngredients)
    const formLoadingForm = useSelector(state => state.RequestedActiveIngredients).formLoading

    const onFinish = (data) => {
        if (!selectedIdentifier){
            form.setFields([{
                name: 'linked_salt_name',
                errors: ['Please Select A Active Ingredient To link'],
            }]);
        }else {
            const formData = {
                salt_name: (data.linked_ai) ? data.linked_ai : requestedActiveIngredient.salt_name,
                ai_identifier: selectedIdentifier,
                notes: (data.notes) ? data.notes : '',
                web_scale: (data.web_scale) ? data.web_scale : '',
                id: requestedActiveIngredient.identifier
            }
            dispatch(editLinkAIRequested(formData, requestedActiveIngredient.identifier))
        }
    }

    useEffect(() => {
        form.resetFields()
    }, [requestedActiveIngredient])

    useEffect(() => {
        const searchResult = [];
        searchedAi.map(item => {
            searchResult.push({
                identifier: item.identifier,
                value: item.salt,
                parent: (item.parent) ? item.parent : {}
            })
        })
        setOptions(searchResult)
    }, [dispatch, searchedAi])

    const search = (value) => {
        if (value.length >= 3 && company.identifier) {
            setOptions([])
            setSelectedIdentifier('')
            setParentName('')
            setSaltName('')
            dispatch(searchActiveIngredients(company.identifier, {query: value}))
        } else {
            setOptions([])
            setSelectedIdentifier('')
            setParentName('')
            setSaltName('')
        }
    }

    const onSelect = (value, option) => {
        setSaltName(option.value)
        if (Object.keys(option.parent).length > 0) {
            setSelectedIdentifier(option.parent.identifier)
            setParentName(option.parent.salt)
        }else {
            setSelectedIdentifier(option.identifier)
        }
    }

    const notification = (type, message) => {
        dispatch(showNotification({ type: type, title: message }))
    };

    if (success !== "") {
        notification("success", success);
        form.resetFields()
        setOptions([])
        setParentName('')
        setSaltName('')
        setSelectedIdentifier('')
        dispatch(closeModalMain());
        dispatch(clearState());
    }
    if (error !== "") {
        if (error === 'Company already has this Active Ingredient' || error === 'This ingredient has already been added/requested'){
            Modal.error({
                title: error,
            });
            dispatch(clearState());
        }else {
            notification("error", error);
            dispatch(clearState());
        }
    }

    return (
        <Spin spinning={formLoadingForm}>
            <Form
                id="edit_link_ai_form"
                name="edit_link_ai_form"
                layout="vertical"
                form={form}
                style={{width: "100%"}}
                onFinish={onFinish}
                initialValues={requestedActiveIngredient}
            >
                <Row gutter={24}>
                    <Col md={24} sm={12} xs={24} style={{marginBottom: 0}}>
                        <Row gutter={24}>
                            <Col md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Search Active Ingredient you want to add"
                                    name="linked_ai"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input link active ingredient name!",
                                        },
                                    ]}
                                    style={(parentName) && {marginBottom: 0}}
                                >
                                    <AutoComplete
                                        dropdownClassName="certain-category-search-dropdown"
                                        dropdownMatchSelectWidth={500}
                                        style={{ width: "100%" }}
                                        options={options}
                                        onSelect={onSelect}
                                        onSearch={search}
                                        onClear={() => setSelectedIdentifier('')}
                                    >
                                        <Input.Search placeholder="Search Active Ingredient" style={{width: "100%"}} loading={formLoading}/>
                                    </AutoComplete>
                                </Form.Item>
                                {(parentName) && <p style={{marginBottom: "10px", marginTop: "5px", color: "#389e0d"}}> {saltName} is listed in our database as {parentName} </p>}
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Scale"
                                    name="web_scale"
                                >
                                    <Input placeholder="Scale" name="scale"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Comments"
                                    name="notes"
                                >
                                    <TextArea rows={6}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <div>
                            <Button form="edit_link_ai_form" key="submit" htmlType="submit" type="primary" loading={formLoadingForm} style={{float: "right"}} disabled={!selectedIdentifier}>Save Changes</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Spin>
    )
}

export default RequestedActiveIngredientLinkForm;

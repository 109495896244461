import {
    CLEAR_ACTIVE_INGREDIENTS,
    CLEAR_ACTIVE_INGREDIENTS_STATE,
    CREATE_ACTIVE_INGREDIENTS,
    CREATE_ACTIVE_INGREDIENTS_FAIL,
    CREATE_ACTIVE_INGREDIENTS_SUCCESS,
    DATA_ERROR_ACTIVE_INGREDIENTS, DELETE_ACTIVE_INGREDIENTS, DELETE_ACTIVE_INGREDIENTS_SUCCESS,
    GET_ACTIVE_INGREDIENTS,
    GET_ACTIVE_INGREDIENTS_SUCCESS,
    GET_AI_SYNONYMS,
    GET_AI_SYNONYMS_SUCCESS,
    GET_PAGINATION_ACTIVE_INGREDIENTS,
    GET_SEARCH_ACTIVE_INGREDIENTS,
    GET_SEARCH_ACTIVE_INGREDIENTS_SUCCESS,
    LINK_ACTIVE_INGREDIENTS, LINK_ACTIVE_INGREDIENTS_FAIL, LINK_ACTIVE_INGREDIENTS_SUCCESS,
    SET_ACTIVE_INGREDIENTS,
    SET_AI_SYNONYMS,
    CREATE_ACTIVE_INGREDIENTS_COMMENTS,
    CREATE_ACTIVE_INGREDIENTS_COMMENTS_SUCCESS,
    CREATE_ACTIVE_INGREDIENTS_COMMENTS_FAIL
} from "./actionTypes";

const initialState = {
    activeIngredients: [],
    searchedAi: [],
    synonyms: [],
    activeIngredient: {},
    loading: false,
    formLoading: false,
    searchLoading: false,
    success: '',
    error: '',
    pagination: {
        current: 1,
        pageSize: 0,
        total: 0
    },
};

const ActiveIngredients = (state = initialState, action) => {
    switch (action.type) {
        case GET_ACTIVE_INGREDIENTS:
            return {
                ...state,
                loading: true
            };
        case GET_ACTIVE_INGREDIENTS_SUCCESS:
            return {
                ...state,
                activeIngredients: action.payload,
                loading: false
            };
        case GET_SEARCH_ACTIVE_INGREDIENTS:
            return {
                ...state,
                searchLoading: true
            };
        case GET_SEARCH_ACTIVE_INGREDIENTS_SUCCESS:
            return {
                ...state,
                searchedAi: action.payload,
                searchLoading: false
            };
        case GET_AI_SYNONYMS:
            return {
                ...state,
                formLoading: true
            };
        case GET_AI_SYNONYMS_SUCCESS:
            return {
                ...state,
                synonyms: action.payload,
                formLoading: false
            };
        case SET_AI_SYNONYMS:
            return {
                ...state,
                synonyms: []
            };
        case SET_ACTIVE_INGREDIENTS:
            return {
                ...state,
                activeIngredient: action.payload
            };
        case CREATE_ACTIVE_INGREDIENTS:
            return {
                ...state,
                formLoading: true
            };
        case CREATE_ACTIVE_INGREDIENTS_SUCCESS:
            return {
                ...state,
                success: 'Active Ingredient request was successfully sent',
                formLoading: false
            };
        case CREATE_ACTIVE_INGREDIENTS_FAIL:
            return {
                ...state,
                error: action.payload,
                formLoading: false,
            };
        case LINK_ACTIVE_INGREDIENTS:
            return {
                ...state,
                formLoading: true
            };
        case LINK_ACTIVE_INGREDIENTS_SUCCESS:
            return {
                ...state,
                success: 'Active Ingredient request was successfully sent',
                formLoading: false
            };
        case LINK_ACTIVE_INGREDIENTS_FAIL:
            return {
                ...state,
                error: action.payload,
                formLoading: false,
            };
        case DELETE_ACTIVE_INGREDIENTS:
            return {
                ...state,
                loading: true
            };
        case DELETE_ACTIVE_INGREDIENTS_SUCCESS:
            return {
                ...state,
                success: 'Request to delete active ingredient was successfully sent',
                loading: false
            };
        case CREATE_ACTIVE_INGREDIENTS_COMMENTS:
            return {
                ...state,
                formLoading: true
            };
        case CREATE_ACTIVE_INGREDIENTS_COMMENTS_SUCCESS:
            let ai = state.activeIngredients.find((obj => obj.identifier == action.payload.identifier));
            ai.comment = action.payload.comment
            return {
                ...state,
                activeIngredients: state.activeIngredients.map((activeIngredient) => activeIngredient.identifier === action.payload.identifier ? ai : activeIngredient),
                success: 'Success',
                formLoading: false
            };
        case CREATE_ACTIVE_INGREDIENTS_COMMENTS_FAIL:
            return {
                ...state,
                error: action.payload,
                formLoading: false,
            };
        case DATA_ERROR_ACTIVE_INGREDIENTS:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case GET_PAGINATION_ACTIVE_INGREDIENTS:
            return {
                ...state,
                pagination: {
                    current: action.payload.current_page,
                    pageSize: action.payload.per_page,
                    total: action.payload.total
                },
            };
        case CLEAR_ACTIVE_INGREDIENTS:
            return {
                ...state,
                activeIngredients: []
            };
        case CLEAR_ACTIVE_INGREDIENTS_STATE:
            return {
                ...state,
                error: '',
                success: '',
                loading: false,
                formLoading: false
            };
        default:
            return state;
    }
}

export default ActiveIngredients;

import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, Row, Select, Popconfirm, Spin, Typography} from "antd";
import basicStyle from "@iso/assets/styles/constants";
import {useDispatch, useSelector} from "react-redux";
import {validateFromServer} from "../../../library/helpers/validators/serverValidation";
import {resetUserPassword, clearState, createUser, updateUser} from "../../../redux/users/actions";
import ChangePswForm from "./ChangePswForm";
import {InfoCircleOutlined} from "@ant-design/icons";

const UserForm = () => {
    const { colStyle } = basicStyle;
    const { TextArea } = Input;
    const {Text} = Typography
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const {error, formData, user, formLoading, formSuccess} = useSelector(state => state.Users)
    const { Option } = Select;

    useEffect(() => {
        form.resetFields()
    }, [user])

    const changePassword = () => {
        dispatch(resetUserPassword(user.id))
    }

    if (error) {
        if (error.code === 422){
            form.setFields(validateFromServer(error));
        }
        dispatch(clearState());
    }

    const onFinish = (data) => {
        const formData =  {
            address: (data.address) ? data.address: '',
            email: data.email,
            first_name: data.first_name,
            last_name: data.last_name,
            password: (data.password) ? data.password: null,
            phone: (data.phone) ? data.phone: '',
            roles: (data.roles) ? data.roles: [],
            companies_representing: (data.companies_representing) ? data.companies_representing: ''
        }
        if (Object.keys(user).length > 0){
            dispatch(updateUser(formData, user.id))
        }else{
            dispatch(createUser(formData))
        }
    }

    return (
        <Spin spinning={formLoading}>
        <Form
            id="users_form"
            name="users_form"
            layout="vertical"
            form={form}
            style={{ width: "100%" }}
            onFinish={onFinish}
            initialValues={user}
        >
            <Row gutter={24}>
                <Col md={24} sm={12} xs={24} style={{...colStyle, marginBottom: 0}}>
                    <Row gutter={24}>
                        <Col md={24} sm={24} xs={24}>
                            <Form.Item
                                label="First Name"
                                name="first_name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input first name!",
                                    },
                                ]}
                            >
                                <Input placeholder="First Name" name="first_name" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Last Name"
                                name="last_name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input last name!",
                                    },
                                ]}
                            >
                                <Input placeholder="Last Name" name="last_name" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input email!",
                                    },
                                ]}
                            >
                                <Input placeholder="Email" name="email" disabled={(Object.keys(user).length === 0) ? false: true}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col md={24} sm={24} xs={24}>
                            {Object.keys(user).length === 0 ?
                                <Form.Item
                                    label="Password"
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input password!",
                                        },
                                    ]}
                                >
                                    <Input.Password placeholder="Password" name="password"/>
                                </Form.Item> :
                                <Popconfirm
                                    title="Are you sure you want to reset this user password"
                                    onConfirm={changePassword}
                                    onCancel={() => {}}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button style={{width: "100%"}}>Reset Password</Button>
                                </Popconfirm>
                            }
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col md={24} sm={24} xs={24}>
                            <Form.Item
                                label="User Role"
                                name="roles"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select a user role!",
                                    },
                                ]}
                            >
                                <Select
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="User Role"
                                >
                                    <Option key={1} value={1}>Admin</Option>
                                    <Option key={2} value={2}>User</Option>
                                    {/*{roles?.map(role => {*/}
                                    {/*    return (<Option key={role.id} value={role.id}>{role.name.slice(0,1).toUpperCase() + role.name.slice(1, role.name.length)}</Option>)*/}
                                    {/*})}*/}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                     <Row gutter={24}>
                        <Col md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Companies Representing"
                                name="companies_representing"
                            >
                                <TextArea rows={3} style={{width: "100%"}}/>
                            </Form.Item>
                            <Text type="secondary">Separate each company with comma (ex. company one, company two)</Text>
                        </Col>
                     </Row>
                    <Row gutter={24}>
                        <Col md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Phone Number"
                                name="phone"
                            >
                                <Input placeholder="Phone Number" name="phone" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Address"
                                name="address"
                            >
                                <Input placeholder="Address" name="address" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
        </Spin>
    )
}

export default UserForm;

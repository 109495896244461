import {Modal, Button, Typography, Space, Form, Input} from "antd";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {createRepCompanies, clearState} from "../../redux/representingCompanies/actions";
import {showNotification} from "../../redux/notification/actions";


const CompaniesRepresenting = () => {
    const dispatch = useDispatch();
    const {error, success, formLoading} = useSelector(state => state.RepresentingCompanies)
    const {token, loginSuccess} = useSelector(state => state.Auth)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const { Text } = Typography;
    const {TextArea} = Input;

    const submit = (data) => {
        dispatch(createRepCompanies(data))
    }

    const skip = () => {
        dispatch(createRepCompanies({companies: ''}))
    }

    useEffect(() => {
        if (localStorage.getItem('user_verified') === "true") {
            setIsModalVisible(localStorage.getItem('companies_representing') === "false")
        }
    }, [localStorage.getItem('companies_representing'), localStorage.getItem('user_verified'), token, loginSuccess])

    const notification = (type, message) => {
        dispatch(showNotification({ type: type, title: message }));
        dispatch(clearState());
    };

    if (success !== "") {
        notification("success", success);
        setIsModalVisible(false)
    }
    if (error !== "") {
        notification("error", error);
    }

    return (
        <Modal
            title="Add Representing Companies"
            visible={isModalVisible}
            keyboard={false}
            closable={false}
            maskClosable ={false}
            width={600}
            style={{zIndex: "9999999"}}
            footer={[
                <Button key="back" onClick={skip} loading={formLoading}>Skip</Button>,
                <Button form="companies_representing_form" key="submit" htmlType="submit" type="primary" loading={formLoading}>Add</Button>
            ]}
        >
            <Text strong style={{fontSize: "16px"}}>Please add companies that you are registering for</Text><br/>
            <Text type="secondary">If multiple, separate each company with a comma (ex. company one, company two)</Text>
            <Form
                name="companies_representing_form"
                onFinish={submit}
                autoComplete="off"
                layout="vertical"
                style={{marginTop: "15px"}}
            >
                <Form.Item
                    label="Companies"
                    name="companies"
                    rules={[{ required: true, message: 'Please input your companies!' }]}
                >
                    <TextArea rows={4} style={{width: "100%"}}/>
                </Form.Item>
            </Form>
            <Text type="secondary" style={{fontSize: "12px"}}>You can skip now and add the company at another time in your profile page</Text>
        </Modal>
    );
}
export default CompaniesRepresenting

import {
    CLEAR_COMPANIES_STATE,
    DATA_ERROR_COMPANIES,
    GET_COMPANIES,
    GET_COMPANIES_SUCCESS, GET_COMPANY, GET_COMPANY_HISTORY, GET_COMPANY_HISTORY_SUCCESS, GET_COMPANY_SUCCESS,
    GET_PAGINATION_COMPANIES, SET_AI_NR, SET_AI_NR_DECLINE,
    SET_COMPANY, SET_COMPANY_HISTORY, SET_CONTACT_NR, SET_CONTACT_NR_DECLINE
} from "./actionTypes";

const initialState = {
    companies: [],
    company: {},
    companyHistory: [],
    loading: false,
    formLoading: false,
    historyLoading: false,
    success: '',
    error: '',
    pagination: {
        current: 1,
        pageSize: 0,
        total: 0
    },
};


const Companies = (state = initialState, action) => {
    switch (action.type) {
        case GET_COMPANIES:
            return {
                ...state,
                loading: true
            };
        case GET_COMPANIES_SUCCESS:
            return {
                ...state,
                companies: action.payload,
                loading: false
            };
        case GET_COMPANY:
            return {
                ...state,
                loading: true
            };
        case GET_COMPANY_SUCCESS:
            return {
                ...state,
                company: action.payload,
                loading: false
            };
        case GET_COMPANY_HISTORY:
            return {
                ...state,
                historyLoading: true
            };
        case GET_COMPANY_HISTORY_SUCCESS:
            return {
                ...state,
                companyHistory: action.payload,
                historyLoading: false
            };

        case SET_COMPANY:
            return {
                ...state,
                company: action.payload
            };

        case SET_COMPANY_HISTORY:
            return {
                ...state,
                companyHistory: action.payload
            };
        case SET_CONTACT_NR:
            return {
                ...state,
                company: {
                    ...state.company,
                    contact_nr: state.company.contact_nr+1
                }
            };
        case SET_CONTACT_NR_DECLINE:
            return {
                ...state,
                company: {
                    ...state.company,
                    contact_nr: state.company.contact_nr-1
                }
            };
        case SET_AI_NR:
            return {
                ...state,
                company: {
                    ...state.company,
                    ai_nr: state.company.ai_nr+1
                }
            };
        case SET_AI_NR_DECLINE:
            return {
                ...state,
                company: {
                    ...state.company,
                    ai_nr: state.company.ai_nr-1
                }
            };
        case DATA_ERROR_COMPANIES:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case GET_PAGINATION_COMPANIES:
            return {
                ...state,
                pagination: {
                    current: action.payload.current_page,
                    pageSize: action.payload.per_page,
                    total: action.payload.total
                },
            };
        case CLEAR_COMPANIES_STATE:
            return {
                ...state,
                error: '',
                success: '',
                loading: false,
                formLoading: false
            };
        default:
            return state;
    }
}

export default Companies;

import React from 'react';
import { Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import {closeModalMain} from '@iso/redux/rootModal/actions';
import UserForm from "../Users/Components/UserForm";
import ActiveIngredientSynonymsTable from "../Companies/ViewCompany/ActiveIngredients/Components/ActiveIngredientSynonymsTable";
import CompanyForm from "../Companies/CompaniesList/Components/CompanyForm";
import ViewCompanyRequest from "../Companies/RequestedCompanies/Components/Modals/ViewCompanyRequest";
import ViewChangedFiles from "../Companies/RequestedCompanies/Components/Modals/ViewChangedFiles";
import RequestedCompanyForm from "../Companies/RequestedCompanies/Components/RequestedCompanyForm";
import ViewRequestChanged from "../Companies/RequestedCompanies/Components/Modals/ViewRequestChanged";
import ContactForm from "../Companies/ViewCompany/Contacts/Components/ContactForm";
import ViewContactRequest from "../Companies/ViewCompany/Contacts/Components/Modals/ViewContactRequest";
import ViewContactRequestChanged from "../Companies/ViewCompany/Contacts/Components/Modals/ViewContactRequestChanged";
import RequestedContactForm from "../Companies/ViewCompany/Contacts/Components/RequestedContactForm";
import AddActiveIngredientForm from "../Companies/ViewCompany/ActiveIngredients/Components/AddActiveIngredientForm";
import ViewActiveIngredientRequestChanged
    from "../Companies/ViewCompany/ActiveIngredients/Components/Modals/viewActiveIngredientRequestChanged";
import LinkActiveIngredientsForm from "../Companies/ViewCompany/ActiveIngredients/Components/LinkActiveIngredientsForm";
import RequestedActiveIngredientForm
    from "../Companies/ViewCompany/ActiveIngredients/Components/Modals/RequestedActiveIngredientForm";
import RequestedActiveIngredientLinkForm
    from "../Companies/ViewCompany/ActiveIngredients/Components/Modals/RequestedActiveIngredientLinkForm";
import CommentsForm from "../Companies/ViewCompany/ActiveIngredients/Components/CommentsForm";

const MODAL_COMPONENTS = {
    USER_FORM: UserForm,
    AI_SYNONYMS_TABLE: ActiveIngredientSynonymsTable,
    COMPANY_REQUESTED_FORM: CompanyForm,
    VIEW_COMPANY_REQUEST: ViewCompanyRequest,
    SHOW_ALL_FIELDS_CHANGED: ViewChangedFiles,
    EDIT_REQUESTED_COMPANY: RequestedCompanyForm,
    VIEW_REQUEST_CHANGED: ViewRequestChanged,
    CONTACT_FORM: ContactForm,
    VIEW_CONTACT_REQUEST: ViewContactRequest,
    VIEW_CONTACT_REQUEST_CHANGED: ViewContactRequestChanged,
    REQUESTED_CONTACT_FORM: RequestedContactForm,
    ADD_AI_FORM: AddActiveIngredientForm,
    LINK_AI_FORM: LinkActiveIngredientsForm,
    VIEW_AI_REQUEST_CHANGED: ViewActiveIngredientRequestChanged,
    REQUESTED_AI_FORM: RequestedActiveIngredientForm,
    REQUESTED_AI_LINK_FORM: RequestedActiveIngredientLinkForm,
    AI_COMMENTS: CommentsForm
};


const RootModal = () => {

  const dispatch = useDispatch();

  const modalVisibility = useSelector((state) => state.rootModal.modalVisibility);
  const modalProps = useSelector((state) => state.rootModal.modalProps);
  const modalType = useSelector((state) => state.rootModal.modalType);
  const modalBodyProps = useSelector((state) => state.rootModal.modalBodyProps);

  const SpecificModal = MODAL_COMPONENTS[modalType];
    return(
      <Modal
        visible={modalVisibility}
        onCancel={() => dispatch(closeModalMain())}
       {...modalProps}
      >
        <SpecificModal  {...modalBodyProps} onCancel={() => dispatch(closeModalMain())} />
      </Modal>
    );

}


export default RootModal;

import {
    CLEAR_CONTACTS,
    CLEAR_CONTACTS_STATE,
    CREATE_CONTACT,
    CREATE_CONTACT_FAIL,
    CREATE_CONTACT_SUCCESS,
    DATA_ERROR_CONTACTS,
    DELETE_CONTACTS,
    DELETE_CONTACTS_SUCCESS,
    EDIT_CONTACT,
    EDIT_CONTACT_FAIL,
    EDIT_CONTACT_SUCCESS,
    GET_CONTACTS,
    GET_CONTACTS_SUCCESS,
    GET_PAGINATION_CONTACTS,
    SET_CONTACT
} from "./actionTypes";


export const getContacts = (company_kp, params) => ({
    type: GET_CONTACTS,
    payload: { company_kp, params }
});

export const getContactsSuccess = (data) => ({
    type: GET_CONTACTS_SUCCESS,
    payload: data
});

export const editContact = (contact, contact_id) => ({
    type: EDIT_CONTACT,
    payload: { contact, contact_id }
});

export const editContactSuccess = (company) => ({
    type: EDIT_CONTACT_SUCCESS,
    payload: company
});

export const editContactFail = (error) => ({
    type: EDIT_CONTACT_FAIL,
    payload: error
});

export const deleteContacts = (contact_id, reason) => ({
    type: DELETE_CONTACTS,
    payload: { contact_id, reason }
});

export const deleteContactsSuccess = (data) => ({
    type: DELETE_CONTACTS_SUCCESS,
    payload: data
});

export const setContact = contact => ({
    type: SET_CONTACT,
    payload: contact,
});

export const createContact = contact => ({
    type: CREATE_CONTACT,
    payload: contact
});

export const createContactSuccess = contact => ({
    type: CREATE_CONTACT_SUCCESS,
    payload: contact,
});

export const createContactFail = error => ({
    type: CREATE_CONTACT_FAIL,
    payload: error,
})

export const setPagination = pagination => ({
    type: GET_PAGINATION_CONTACTS,
    payload: pagination,
});

export const dataError = error => ({
    type: DATA_ERROR_CONTACTS,
    payload: error,
});

export const clearContacts = () => ({
    type: CLEAR_CONTACTS
});

export const clearState = () => ({
    type: CLEAR_CONTACTS_STATE
});

import {
    GET_REPORT,
    GET_REPORT_SUCCESS,
    GET_REPORT_COMPANIES,
    GET_REPORT_COMPANIES_SUCCESS,
    GET_REPORT_DATA_ERROR,
    CLEAR_REPORT_STATE,
    GET_REPORT_PAGINATION,
    SET_REPORT,
    GET_SINGLE_REPORT,
    GET_SINGLE_REPORT_SUCCESS,
    SET_REPORT_META,
    GET_COMPANY_PDF_REPORT,
    GET_COMPANY_PDF_REPORT_SUCCESS,
    GET_REQUESTED_PDF_REPORT,
    GET_REQUESTED_PDF_REPORT_SUCCESS, GET_DASHBOARD_REPORT, GET_DASHBOARD_REPORT_SUCCESS
} from "./actionTypes";

export const getReport = (params) => ({
    type: GET_REPORT,
    filters: {...params}
});

export const getReportSuccess = (data) => ({
    type: GET_REPORT_SUCCESS,
    payload: data
});

export const setSingleReport = (data) => ({
    type: SET_REPORT,
    payload: data
});

export const setSingleReportMeta = (data) => ({
    type: SET_REPORT_META,
    payload: data
});

export const getReportCompanies = () => ({
    type: GET_REPORT_COMPANIES,
});

export const getReportCompaniesSuccess = (data) => ({
    type: GET_REPORT_COMPANIES_SUCCESS,
    payload: data
});

export const getCompanyPdfReport = (company_identifier) => ({
    type: GET_COMPANY_PDF_REPORT,
    payload: { company_identifier }
});

export const getCompanyPdfReportSuccess = (data) => ({
    type: GET_COMPANY_PDF_REPORT_SUCCESS,
    payload: data
});

export const getRequestedPdfReport = (company_identifier) => ({
    type: GET_REQUESTED_PDF_REPORT,
    payload: { company_identifier }
});

export const getRequestedPdfReportSuccess = (data) => ({
    type: GET_REQUESTED_PDF_REPORT_SUCCESS,
    payload: data
});

export const getDashboardReport = () => ({
    type: GET_DASHBOARD_REPORT,
});

export const getDashboardReportSuccess = (data) => ({
    type: GET_DASHBOARD_REPORT_SUCCESS,
    payload: data
});


export const getSingleReport = (identifier, request_for) => ({
    type: GET_SINGLE_REPORT,
    payload: { identifier, request_for}
});

export const getSingleReportSuccess = (data) => ({
    type: GET_SINGLE_REPORT_SUCCESS,
    payload: data
});

export const dataErrorReport = error => ({
    type: GET_REPORT_DATA_ERROR,
    payload: error,
})

export const setPagination = pagination => ({
    type: GET_REPORT_PAGINATION,
    payload: pagination,
});

export const clearState = () => ({
    type: CLEAR_REPORT_STATE
});


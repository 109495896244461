import {del, get, post, postUpload, put} from "./apiHelpers";
import * as url from './urlHelpers';
import {
    ACTIVE_INGREDIENTS,
    FORGOT_PASSWORD,
    PASSWORD_RESET, PROFILE,
    REP_COMPANIES, REPORT, REQUESTED_ACTIVE_INGREDIENTS,
    REQUESTED_COMPANIES,
    VERIFY_EMAIL_URL
} from "./urlHelpers";

// Login Method
const postLogin = data => post(url.POST_LOGIN, data);
// Register Method
const postRegister = data => post(url.POST_REGISTER, data);
//Verify Email
const verifyEmailRequest = (user_id, hash) => get(`${url.VERIFY_EMAIL_URL}/${user_id}/${hash}`);
//resend verification email
const resendVerificationEmail = () => post(url.RESEND_VERIFICATION_EMAIL);
//resend verification email
const resetPasswordRequest = (data) => post(url.PASSWORD_CHANGE, data);
//forgot email
const forgotPasswordRequest = (data) => post(url.FORGOT_PASSWORD, data);
//password reset
const passwordResetRequest = (data) => post(url.PASSWORD_RESET, data);
//password reset
const createRepCompaniesRequest = (data) => post(url.REP_COMPANIES, data);
// User State Method
const getUserState = () => get(url.GET_USER_STATE);
const getUserProfile = () => get(url.PROFILE)
const editUserProfile = (data) => put(url.PROFILE, data)
// Menu Methods
const getMenu = data => get(url.GET_MENU);
// Route Methods
const getRoutes = data => get(url.GET_ROUTES);

//Users Methods
const getUsers = (data) => get(url.USERS, {params: data});
const createUsers = (user) => post(url.USERS, user);
const editUser = (user, user_id) => put(url.USERS+'/'+user_id+'/edit', user)
const deleteUserRequest = (user_id) => del(url.USERS+'/'+user_id+'/delete')
const userPasswordChange = (user_id) => get(url.USERS+'/'+user_id+'/reset/password')

//Companies Methods
const getCompanies= (data) => get(url.COMPANIES, {params: data});
const getCompany = (company_kp) => get(url.COMPANIES+'/'+company_kp);
const getCompanyHistory = (company_kp) => get(url.COMPANIES+'/'+company_kp+'/history');
const getRequestedCompanies = (params) => get(url.REQUESTED_COMPANIES, {params: params});
const createCompanyRequest = (request, identifier) => put(`${url.COMPANIES}/${identifier}/edit`, request);
const updateCompanyRequest = (request, id) => put(`${url.REQUESTED_COMPANIES}/${id}/edit`, request);
const deleteRequest = (id) => del(`${url.REQUESTED_COMPANIES}/${id}/delete`);

//contacts
const getContacts = (company_kp, params) => get(url.CONTACTS+'/'+company_kp, {params: params});
const createContacts = (contact) => post(url.CONTACTS, contact);
const getRequestedContacts = (company_kp, params) => get(url.REQUESTED_CONTACTS+'/'+company_kp, {params: params});
const updateContacts = (contact, contact_id) => put(url.CONTACTS+'/'+contact_id+'/edit', contact);
const deleteContact = (contact_id, data) => put(url.CONTACTS+'/'+contact_id+'/delete', data);
const editRequestedContactHttps = (requested_contact, requested_contact_id) => put(url.REQUESTED_CONTACTS+'/'+requested_contact_id+'/edit', requested_contact)
const deleteRequestedContactHttps = (requested_contact_id) => del(url.REQUESTED_CONTACTS+'/'+requested_contact_id+'/delete')
// ActiveIngredients Methods
const getActiveIngredients = (company_kp, params) => get(url.ACTIVE_INGREDIENTS+'/'+company_kp, {params: params});
const getRequestedActiveIngredients = (company_identifier, params) => get(url.REQUESTED_ACTIVE_INGREDIENTS+'/'+company_identifier, {params: params});
const getAiSynonyms = (company_kp, ai_id, params) => get(url.ACTIVE_INGREDIENTS+'/'+company_kp+'/synonyms/'+ai_id, {params: params});
const createActiveIngredients = (activeIngredient, company_identifier) => post(url.ACTIVE_INGREDIENTS+'/'+company_identifier+'/create', activeIngredient);
const linkActiveIngredients = (activeIngredient, company_identifier) => post(url.ACTIVE_INGREDIENTS+'/'+company_identifier+'/link', activeIngredient);
const searchActiveIngredients = (company_identifier, params) => get(url.ACTIVE_INGREDIENTS+'/'+company_identifier+'/search', {params: params});
const deleteActiveIngredient = (company_id, ai_id, data) => post(url.ACTIVE_INGREDIENTS+'/'+company_id+'/delete/'+ai_id, data);
const createAIComment = (identifier, comment) => put(url.ACTIVE_INGREDIENTS+'/'+identifier+'/comment', {comment: comment});

const updateRequestActiveIngredient =  (activeIngredient, activeIngredient_id) => put(url.REQUESTED_ACTIVE_INGREDIENTS+'/'+activeIngredient_id+'/edit', activeIngredient);
const updateRequestLinkedActiveIngredient = (activeIngredient, activeIngredient_id) => put(url.REQUESTED_ACTIVE_INGREDIENTS+'/'+activeIngredient_id+'/link-edit', activeIngredient);
const deleteRequestedActiveIngredientHttps = (activeIngredient_id) => del(url.REQUESTED_ACTIVE_INGREDIENTS+'/'+activeIngredient_id+'/delete')

const reportRequest = (data) => get(url.REPORT, {params: data})
const reportCompanies = () => get(url.REPORT+'/companies')
const getSingleReport = (identifier, request_for) => get('/view-report/'+identifier+'/'+request_for)
const getCompanyPdfReport = (identifier) => get('/view-company-report-pdf/'+identifier)
const getRequestedPdfReport = (identifier) => get('/view-requested-report-pdf/'+identifier)
const getDashboardReport = () => get('/dashboard-report')

export {
    postLogin,
    postRegister,
    verifyEmailRequest,
    resendVerificationEmail,
    resetPasswordRequest,
    getUserState,
    getMenu,
    getRoutes,
    getUsers,
    createUsers,
    editUser,
    deleteUserRequest,
    userPasswordChange,
    passwordResetRequest,
    forgotPasswordRequest,
    createRepCompaniesRequest,
    getCompanies,
    getCompany,
    getContacts,
    getActiveIngredients,
    createAIComment,
    getAiSynonyms,
    getRequestedCompanies,
    createCompanyRequest,
    updateCompanyRequest,
    deleteRequest,
    getCompanyHistory,
    createContacts,
    getRequestedContacts,
    updateContacts,
    deleteContact,
    editRequestedContactHttps,
    createActiveIngredients,
    linkActiveIngredients,
    deleteRequestedContactHttps,
    getRequestedActiveIngredients,
    searchActiveIngredients,
    deleteActiveIngredient,
    updateRequestActiveIngredient,
    updateRequestLinkedActiveIngredient,
    deleteRequestedActiveIngredientHttps,
    getUserProfile,
    editUserProfile,
    reportRequest,
    reportCompanies,
    getSingleReport,
    getCompanyPdfReport,
    getRequestedPdfReport,
    getDashboardReport
}

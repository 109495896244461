import {
    GET_REQUESTED_ACTIVE_INGREDIENTS,
    GET_REQUESTED_ACTIVE_INGREDIENTS_SUCCESS,
    SET_REQUESTED_ACTIVE_INGREDIENTS,
    GET_REQUESTED_AI_SYNONYMS,
    GET_REQUESTED_AI_SYNONYMS_SUCCESS,
    CLEAR_REQUESTED_ACTIVE_INGREDIENTS,
    GET_PAGINATION_REQUESTED_ACTIVE_INGREDIENTS,
    DATA_ERROR_REQUESTED_ACTIVE_INGREDIENTS,
    CLEAR_REQUESTED_ACTIVE_INGREDIENTS_STATE,
    NEW_REQUESTED_ACTIVE_INGREDIENTS,
    SET_ACTIVE_INGREDIENTS_REQUEST_HISTORY,
    DELETE_REQUESTED_ACTIVE_INGREDIENTS,
    DELETE_REQUESTED_ACTIVE_INGREDIENTS_SUCCESS,
    EDIT_REQUESTED_ACTIVE_INGREDIENTS,
    EDIT_REQUESTED_ACTIVE_INGREDIENTS_SUCCESS,
    EDIT_REQUESTED_ACTIVE_INGREDIENTS_FAIL,
    EDIT_LINK_REQUESTED_ACTIVE_INGREDIENTS,
    EDIT_LINK_REQUESTED_ACTIVE_INGREDIENTS_SUCCESS, EDIT_LINK_REQUESTED_ACTIVE_INGREDIENTS_FAIL
} from "./actionTypes";


export const getRequestedActiveIngredients = (company_identifier, params) => ({
    type: GET_REQUESTED_ACTIVE_INGREDIENTS,
    payload: { company_identifier, params }
});

export const getRequestedActiveIngredientsSuccess = (data) => ({
    type: GET_REQUESTED_ACTIVE_INGREDIENTS_SUCCESS,
    payload: data
});

export const getRequestedAiSynonyms = (company_identifier, ai_id, params) => ({
    type: GET_REQUESTED_AI_SYNONYMS,
    payload: { company_identifier, ai_id, params }
});

export const getRequestedAiSynonymsSuccess = (data) => ({
    type: GET_REQUESTED_AI_SYNONYMS_SUCCESS,
    payload: data
});

export const setRequestedActiveIngredients = (data) => ({
    type: SET_REQUESTED_ACTIVE_INGREDIENTS,
    payload: data
});

export const newRequestedActiveIngredient = (data) => ({
    type: NEW_REQUESTED_ACTIVE_INGREDIENTS,
    payload: data
});

export const setActiveIngredientRequestHistory = (history) => ({
    type: SET_ACTIVE_INGREDIENTS_REQUEST_HISTORY,
    payload: history
})


export const setPagination = pagination => ({
    type: GET_PAGINATION_REQUESTED_ACTIVE_INGREDIENTS,
    payload: pagination,
});

export const editAIRequested = (request, id) => ({
    type: EDIT_REQUESTED_ACTIVE_INGREDIENTS,
    payload: {request, id}
});

export const editAIRequestedSuccess = request => ({
    type: EDIT_REQUESTED_ACTIVE_INGREDIENTS_SUCCESS,
    payload: request,
});

export const editAIRequestedFail = error => ({
    type: EDIT_REQUESTED_ACTIVE_INGREDIENTS_FAIL,
    payload: error,
})

export const editLinkAIRequested = (request, id) => ({
    type: EDIT_LINK_REQUESTED_ACTIVE_INGREDIENTS,
    payload: {request, id}
});

export const editLinkAIRequestedSuccess = request => ({
    type: EDIT_LINK_REQUESTED_ACTIVE_INGREDIENTS_SUCCESS,
    payload: request,
});

export const editLinkAIRequestedFail = error => ({
    type: EDIT_LINK_REQUESTED_ACTIVE_INGREDIENTS_FAIL,
    payload: error,
})

export const deleteAiRequest = (id) => ({
    type: DELETE_REQUESTED_ACTIVE_INGREDIENTS,
    payload: {id}
});

export const deleteAiRequestSuccess = (id) => ({
    type: DELETE_REQUESTED_ACTIVE_INGREDIENTS_SUCCESS,
    payload: id
});

export const dataError = error => ({
    type: DATA_ERROR_REQUESTED_ACTIVE_INGREDIENTS,
    payload: error,
});

export const clearActiveIngredients = () => ({
    type: CLEAR_REQUESTED_ACTIVE_INGREDIENTS
});

export const clearState = () => ({
    type: CLEAR_REQUESTED_ACTIVE_INGREDIENTS_STATE
});

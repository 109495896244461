export const GET_COMPANIES = "GET_COMPANIES";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";

export const GET_COMPANY = "GET_COMPANY";
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS";

export const GET_COMPANY_HISTORY = "GET_COMPANY_HISTORY";
export const GET_COMPANY_HISTORY_SUCCESS = "GET_COMPANY_HISTORY_SUCCESS";

export const SET_COMPANY = "SET_COMPANY";
export const SET_COMPANY_HISTORY = "SET_COMPANY_HISTORY";

export const SET_CONTACT_NR = "SET_CONTACT_NR";
export const SET_CONTACT_NR_DECLINE = "SET_CONTACT_NR_DECLINE";

export const SET_AI_NR = "SET_AI_NR";
export const SET_AI_NR_DECLINE = "SET_AI_NR_DECLINE";

export const GET_PAGINATION_COMPANIES = "GET_PAGINATION_COMPANIES";
export const DATA_ERROR_COMPANIES = "DATA_ERROR_COMPANIES";
export const CLEAR_COMPANIES_STATE = "CLEAR_COMPANIES_STATE";

import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import {createRepCompaniesError, createRepCompaniesSuccess} from "./actions";
import {createRepCompaniesRequest} from "../../library/http/backendHelpers";
import {CREATE_REP_COMPANY} from "./actionTypes";


export function* createRepCompaniesSaga({ payload: { data }}) {
    try {
        const response = yield call(createRepCompaniesRequest, data);
        yield put(createRepCompaniesSuccess(response))
        localStorage.setItem("companies_representing", "true");
    } catch (error) {
        yield put(createRepCompaniesError(error))
    }
}


function* representingCompanies() {
    yield takeEvery(CREATE_REP_COMPANY, createRepCompaniesSaga);
}


export default representingCompanies

import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, Row, Select, Spin, Typography,  Divider } from "antd";
import {useDispatch, useSelector} from "react-redux";
import { PlusOutlined } from '@ant-design/icons';
import basicStyle from "@iso/assets/styles/constants";
import {createContact, clearState, editContact} from "../../../../../redux/contacts/contactList/actions";
import {showNotification} from "../../../../../redux/notification/actions";
import {closeModalMain} from "../../../../../redux/rootModal/actions";

const ContactForm = () => {
    let index = 0;
    const {colStyle} = basicStyle;
    const {Text} = Typography
    const { TextArea } = Input;
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const {company} = useSelector(state => state.Companies)
    const {contact, success, error, formLoading} = useSelector(state => state.Contacts)
    const { Option } = Select;
    const [types, setTypes] = useState(
        (Object.keys(contact).length > 0) ?
            contact?.contact_type !== 'null' ?
            (['Editorial', 'Sales'].includes(contact?.contact_type)) ?
                ['Editorial', 'Sales'] :
                ['Editorial', 'Sales', contact?.contact_type] :
            ['Editorial', 'Sales'] : ['Editorial', 'Sales'] );
    const [tmpType, setTmpType] = useState('');
    const [title, setTitle] = useState(
        (Object.keys(contact).length > 0) ?
        (contact.title !== null) ?
        (['Ing.', 'Dr.', 'Ing. Dr.'].includes(contact?.title)) ?
            ['Ing.', 'Dr.', 'Ing. Dr.'] :
            ['Ing.', 'Dr.', 'Ing. Dr.', contact?.title] :
        ['Ing.', 'Dr.', 'Ing. Dr.'] : ['Ing.', 'Dr.', 'Ing. Dr.']);
    const [tmpTitle, setTmpTitle] = useState('');

     useEffect(() => {
         form.resetFields()
     }, [contact])

    const onNameChange = event => {
        setTmpType(event.target.value);
    };

    const onTitleChange = event => {
        setTmpTitle(event.target.value);
    };

    const addItem = e => {
        e.preventDefault();
        setTypes([...types, tmpType || `New item ${index++}`]);
        setTmpType('');
    };

    const addTitle = e => {
        e.preventDefault();
        setTitle([...title, tmpTitle || `New item ${index++}`]);
        setTmpTitle('');
    };

    const onFinish = (data) => {
        if (company?.identifier) {
            if(Object.keys(contact).length > 0) {
            const formData = {
                company_id: company.identifier,
                contact_name: (data.contact_name) ? data.contact_name : '',
                email: (data.email) ? data.email : '',
                contact_type: (data.contact_type) ? data.contact_type : '',
                title: (data.title) ? data.title : '',
                permission_to_contact: (data.permission_to_contact) ? data.permission_to_contact : '',
                notes: (data.notes) ? data.notes : '',
            }
               dispatch(editContact(formData, contact.identifier))
            }
            else {
                const formData = {
                    company_id: company.identifier,
                    contact_name: (data.contact_name) ? data.contact_name : '',
                    email: (data.email) ? data.email : '',
                    contact_type: (data.contact_type) ? data.contact_type : '',
                    title: (data.title) ? data.title : '',
                    permission_to_contact: (data.permission_to_contact) ? data.permission_to_contact : '',
                    notes: (data.notes) ? data.notes : '',
                }
                dispatch(createContact(formData))
            }
        }
    }
    const notification = (type, message) => {
        dispatch(showNotification({ type: type, title: message }))
    };

    if (success !== "") {
        notification("success", success);
        dispatch(closeModalMain());
        dispatch(clearState());
    }
    if (error !== "") {
        notification("error", error.message);
        dispatch(clearState());
    }
    return (
        <Spin spinning={formLoading}>
            <Form
                id="contact_form"
                name="contact_form"
                layout="vertical"
                form={form}
                style={{width: "100%"}}
                onFinish={onFinish}
                initialValues={contact}
            >
                <Row gutter={24}>
                    <Col md={24} sm={12} xs={24} style={{marginBottom: 0}}>
                        <Row gutter={24}>
                            <Col md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Contact Name"
                                    name="contact_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input last contact name!",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Contact Name" name="company_name"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input email!",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Email" name="email"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Contact Type"
                                    name="contact_type"
                                >
                                    <Select
                                        placeholder="Contact Type"
                                        allowClear
                                        dropdownRender={menu => (
                                            <>
                                                {menu}
                                                <Divider style={{ margin: '8px 0' }} />
                                                <Row gutter={24} style={{ padding: '0 8px 4px'}}>
                                                    <Col md={18} sm={18} xs={18}>
                                                        <Input placeholder="Enter a new type" value={tmpType} onChange={onNameChange} style={{width: "100%"}}/>
                                                    </Col>
                                                    <Col md={6} sm={6} xs={6} style={{margin: "auto"}}>
                                                        <Typography.Link onClick={addItem} style={{ whiteSpace: 'nowrap' }}>
                                                            <PlusOutlined /> Add Type
                                                        </Typography.Link>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                    >
                                        {types.map(item => (
                                            <Option key={item}>{item}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Title"
                                    name="title"
                                >
                                    <Select
                                        placeholder="Contact Title"
                                        allowClear
                                        dropdownRender={menu => (
                                            <>
                                                {menu}
                                                <Divider style={{ margin: '8px 0' }} />
                                                <Row gutter={24} style={{ padding: '0 8px 4px'}}>
                                                    <Col md={18} sm={18} xs={18}>
                                                        <Input placeholder="Enter a new title" value={tmpTitle} onChange={onTitleChange} style={{width: "100%"}}/>
                                                    </Col>
                                                    <Col md={6} sm={6} xs={6} style={{margin: "auto"}}>
                                                        <Typography.Link onClick={addTitle} style={{ whiteSpace: 'nowrap', verticalAlign: "middle", margin: 'auto'}} align="center">
                                                            <PlusOutlined /> Add Title
                                                        </Typography.Link>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                    >
                                        {title.map(item => (
                                            <Option key={item}>{item}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col md={24} sm={24} xs={12}>
                                <Form.Item
                                    label="Permission To Contact"
                                    name="permission_to_contact"
                                >
                                    <Select placeholder="Permission To Contact" allowClear>
                                        <Option value="Yes">Yes</Option>
                                        <Option value="No">No</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Notes"
                                    name="notes"
                                >
                                    <TextArea rows={3} name="notes"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <div>
                            <Button form="contact_form" key="submit" htmlType="submit" type="primary" loading={formLoading} style={{float: "right"}}>{Object.keys(contact).length > 0 ? 'Save Changes' : 'Add Contact'}</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Spin>
    )

}

export default ContactForm;

import {
    GET_REPORT,
    GET_REPORT_SUCCESS,
    GET_REPORT_COMPANIES,
    GET_REPORT_COMPANIES_SUCCESS,
    GET_REPORT_DATA_ERROR,
    CLEAR_REPORT_STATE,
    GET_REPORT_PAGINATION,
    SET_REPORT,
    GET_SINGLE_REPORT,
    GET_SINGLE_REPORT_SUCCESS,
    SET_REPORT_META,
    GET_COMPANY_PDF_REPORT,
    GET_COMPANY_PDF_REPORT_SUCCESS,
    GET_REQUESTED_PDF_REPORT,
    GET_REQUESTED_PDF_REPORT_SUCCESS, GET_DASHBOARD_REPORT, GET_DASHBOARD_REPORT_SUCCESS
} from "./actionTypes";

const initialState = {
    reports: [],
    report: {},
    reportCompanyData: {},
    reportRequestedData: {},
    reportMeta: {},
    companies: [],
    dashboardReport: {},
    loading: false,
    formLoading: false,
    reportLoading: false,
    pdfLoading: false,
    dashboardLoading: false,
    success: '',
    error: '',
    pagination: {
        current: 1,
        pageSize: 0,
        total: 0
    },
};

const Report = (state = initialState, action) => {
    switch (action.type) {
        case GET_REPORT:
            return {
                ...state,
                loading: true
            };
        case GET_REPORT_SUCCESS:
            return {
                ...state,
                reports: action.payload,
                loading: false
            }
        case GET_REPORT_COMPANIES:
            return {
                ...state,
                formLoading: true
            };
        case SET_REPORT:
            return {
                ...state,
                report: action.payload
            };
        case SET_REPORT_META:
            return {
                ...state,
                reportMeta: action.payload
            };
        case GET_REPORT_COMPANIES_SUCCESS:
            return {
                ...state,
                companies: action.payload,
                formLoading: false
            };
        case GET_REPORT_DATA_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
                formLoading: false,
                reportLoading: false,
                pdfLoading: false,
                dashboardLoading: false
            };
        case GET_SINGLE_REPORT:
            return {
                ...state,
                reportLoading: true
            };
        case GET_SINGLE_REPORT_SUCCESS:
            return {
                ...state,
                reportData: action.payload,
                reportLoading: false
            }
        case GET_COMPANY_PDF_REPORT:
            return {
                ...state,
                pdfLoading: true
            };
        case GET_COMPANY_PDF_REPORT_SUCCESS:
            return {
                ...state,
                reportCompanyData: action.payload,
                pdfLoading: false
            }
        case GET_REQUESTED_PDF_REPORT:
            return {
                ...state,
                pdfLoading: true
            };
        case GET_REQUESTED_PDF_REPORT_SUCCESS:
            return {
                ...state,
                reportRequestedData: action.payload,
                pdfLoading: false
            }
        case GET_DASHBOARD_REPORT:
            return {
                ...state,
                dashboardLoading: true
            };
        case GET_DASHBOARD_REPORT_SUCCESS:
            return {
                ...state,
                dashboardReport: action.payload,
                dashboardLoading: false
            }
        case GET_REPORT_PAGINATION:
            return {
                ...state,
                pagination: {
                    current: action.payload.current_page,
                    pageSize: action.payload.per_page,
                    total: action.payload.total
                },
            };
        case CLEAR_REPORT_STATE:
            return {
                ...state,
                error: '',
                success: '',
                loading: false,
                formLoading: false,
                reportLoading: false,
                pdfLoading: false,
                dashboardLoading: false
            };
        default:
            return state;
    }
}

export default Report;

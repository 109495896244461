import {
    GET_USERS,
    GET_USERS_SUCCESS,
    CLEAR_USERS_STATE,
    DATA_ERROR_USERS,
    CREATE_USER,
    CREATE_USER_SUCCESS,
    CREATE_USER_FAIL,
    SET_USER,
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAIL,
    DELETE_USER,
    DELETE_USER_SUCCESS,
    CHANGE_USER_PASSWORD,
    CHANGE_USER_PASSWORD_SUCCESS,
    CHANGE_USER_PASSWORD_FAIL,
    GET_PAGINATION_USERS, RESET_USER_PASSWORD, RESET_USER_PASSWORD_SUCCESS
} from "./actionTypes";

const initialState = {
    users: [],
    user: {},
    pagination: {
        current: 1,
        pageSize: 0,
        total: 0
    },
    success: "",
    error: null,
    formSuccess: "",
    formError: null,
    loading: false,
    formLoading: false,
}
const Users = (state = initialState, action) => {
    switch (action.type) {
        case GET_USERS:
            return {
                ...state,
                loading: true
            };
        case GET_USERS_SUCCESS:
            return {
                ...state,
                users: action.payload,
                loading: false
            };
        case CREATE_USER:
            return {
                ...state,
                formLoading: true
            };
        case CREATE_USER_SUCCESS:
            return {
                ...state,
                success: 'User was successfully created',
                users: [action.payload, ...state.users],
                formLoading: false
            };
        case CREATE_USER_FAIL:
            return {
                ...state,
                error: action.payload,
                formLoading: false,
            };
        case SET_USER:
            return {
                ...state,
                user: action.payload
            };
        case UPDATE_USER:
            return {
                ...state,
                formLoading: true,
            };
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                users: state.users.map((user) => user.id === action.payload.id ? action.payload : user),
                success: "User was successfully edited",
                formLoading: false,
            };
        case UPDATE_USER_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case DELETE_USER:
            return {
                ...state,
                loading: true
            };
        case DELETE_USER_SUCCESS:
            return {
                ...state,
                users: state.users.filter(user => user.id !== action.payload),
                success: "User was successfully deleted",
                loading: false
            };
        case RESET_USER_PASSWORD:
            return {
                ...state,
                formLoading: true
            };
        case RESET_USER_PASSWORD_SUCCESS:
            return {
                ...state,
                success: "User password was successfully reset",
                formLoading: false
            };
        case CHANGE_USER_PASSWORD:
            return {
                ...state,
                formLoading: true,
            };
        case CHANGE_USER_PASSWORD_SUCCESS:
            return {
                ...state,
                formSuccess: "User Password was successfully changed",
                formLoading: false,
            };
        case CHANGE_USER_PASSWORD_FAIL:
            return {
                ...state,
                formError: action.payload,
                formLoading: false,
            };
        case DATA_ERROR_USERS:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case GET_PAGINATION_USERS:
            return {
                ...state,
                pagination: {
                    current: action.payload.current_page,
                    pageSize: action.payload.per_page,
                    total: action.payload.total
                },
            };
        case CLEAR_USERS_STATE:
            return {
                ...state,
                error: null,
                success: '',
                loading: false,
                formError: null,
                formSuccess: ''
            };
        default:
            return state
    }
}

export default Users

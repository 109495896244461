import {
    CLEAR_ACTIVE_INGREDIENTS,
    CLEAR_ACTIVE_INGREDIENTS_STATE,
    CREATE_ACTIVE_INGREDIENTS,
    CREATE_ACTIVE_INGREDIENTS_COMMENTS,
    CREATE_ACTIVE_INGREDIENTS_COMMENTS_FAIL,
    CREATE_ACTIVE_INGREDIENTS_COMMENTS_SUCCESS,
    CREATE_ACTIVE_INGREDIENTS_FAIL,
    CREATE_ACTIVE_INGREDIENTS_SUCCESS,
    DATA_ERROR_ACTIVE_INGREDIENTS,
    DELETE_ACTIVE_INGREDIENTS,
    DELETE_ACTIVE_INGREDIENTS_SUCCESS,
    GET_ACTIVE_INGREDIENTS,
    GET_ACTIVE_INGREDIENTS_SUCCESS,
    GET_AI_SYNONYMS,
    GET_AI_SYNONYMS_SUCCESS,
    GET_PAGINATION_ACTIVE_INGREDIENTS,
    GET_SEARCH_ACTIVE_INGREDIENTS,
    GET_SEARCH_ACTIVE_INGREDIENTS_SUCCESS,
    LINK_ACTIVE_INGREDIENTS,
    LINK_ACTIVE_INGREDIENTS_FAIL,
    LINK_ACTIVE_INGREDIENTS_SUCCESS,
    SET_ACTIVE_INGREDIENTS,
    SET_AI_SYNONYMS
} from "./actionTypes";


export const getActiveIngredients = (company_kp, params) => ({
    type: GET_ACTIVE_INGREDIENTS,
    payload: { company_kp, params }
});

export const getActiveIngredientsSuccess = (data) => ({
    type: GET_ACTIVE_INGREDIENTS_SUCCESS,
    payload: data
});

export const searchActiveIngredients = (company_kp, params) => ({
    type: GET_SEARCH_ACTIVE_INGREDIENTS,
    payload: { company_kp, params }
});

export const searchActiveIngredientsSuccess = (data) => ({
    type: GET_SEARCH_ACTIVE_INGREDIENTS_SUCCESS,
    payload: data
});


export const getAiSynonyms = (company_kp, ai_id, params) => ({
    type: GET_AI_SYNONYMS,
    payload: { company_kp, ai_id, params }
});

export const getAiSynonymsSuccess = (data) => ({
    type: GET_AI_SYNONYMS_SUCCESS,
    payload: data
});

export const setAiSynonyms = (data) => ({
    type: SET_AI_SYNONYMS,
    payload: data
});

export const setActiveIngredients = (data) => ({
    type: SET_ACTIVE_INGREDIENTS,
    payload: data
});

export const createActiveIngredients = (activeIngredient, company_identifier) => ({
    type: CREATE_ACTIVE_INGREDIENTS,
    payload: {activeIngredient, company_identifier}
});

export const createActiveIngredientsSuccess = activeIngredient => ({
    type: CREATE_ACTIVE_INGREDIENTS_SUCCESS,
    payload: activeIngredient,
});

export const createActiveIngredientsFail = error => ({
    type: CREATE_ACTIVE_INGREDIENTS_FAIL,
    payload: error,
})

export const linkActiveIngredients = (activeIngredient, company_identifier) => ({
    type: LINK_ACTIVE_INGREDIENTS,
    payload: {activeIngredient, company_identifier}
});

export const linkActiveIngredientsSuccess = activeIngredient => ({
    type: LINK_ACTIVE_INGREDIENTS_SUCCESS,
    payload: activeIngredient,
});

export const linkActiveIngredientsFail = error => ({
    type: LINK_ACTIVE_INGREDIENTS_FAIL,
    payload: error,
})

export const deleteActiveIngredient = (company_id, ai_id, reason, identifier_linkage) => ({
    type: DELETE_ACTIVE_INGREDIENTS,
    payload: { company_id, ai_id, reason, identifier_linkage}
});

export const deleteActiveIngredientSuccess = (data) => ({
    type: DELETE_ACTIVE_INGREDIENTS_SUCCESS,
    payload: data
});

export const createActiveIngredientsComment = (identifier, comment) => ({
    type: CREATE_ACTIVE_INGREDIENTS_COMMENTS,
    payload: {identifier, comment}
});

export const createActiveIngredientsCommentSuccess = (identifier, comment) => ({
    type: CREATE_ACTIVE_INGREDIENTS_COMMENTS_SUCCESS,
    payload: {identifier, comment},
});

export const createActiveIngredientsCommentFail = error => ({
    type: CREATE_ACTIVE_INGREDIENTS_COMMENTS_FAIL,
    payload: error,
})

export const setPagination = pagination => ({
    type: GET_PAGINATION_ACTIVE_INGREDIENTS,
    payload: pagination,
});

export const dataError = error => ({
    type: DATA_ERROR_ACTIVE_INGREDIENTS,
    payload: error,
});

export const clearActiveIngredients = () => ({
    type: CLEAR_ACTIVE_INGREDIENTS
});

export const clearState = () => ({
    type: CLEAR_ACTIVE_INGREDIENTS_STATE
});

import React, {useEffect} from "react";
import {Table, Tooltip, Typography} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {
    clearActiveIngredients,
    getActiveIngredients,
    getAiSynonyms
} from "../../../../../redux/activeIngredients/activeIngredientsList/actions";

const ActiveIngredientSynonymsTable = () => {
    const {synonyms, activeIngredient, formLoading} = useSelector(state => state.ActiveIngredients);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAiSynonyms(activeIngredient.company_id, activeIngredient.ids))
    }, [activeIngredient])

    const columns = [
        {
            title: 'Active Ingredient ',
            dataIndex: 'synonym',
            align: 'left'
        },
        {
            title: 'Cas',
            dataIndex: 'cas',
            align: 'left',
        }
    ]
    return (
        <Table
        columns={columns}
        dataSource={synonyms}
        loading={formLoading}
    />)
}

export default ActiveIngredientSynonymsTable;

import React, {useEffect, useState} from "react";
import {Divider, Table, Typography} from "antd";
import {useSelector} from "react-redux";

const ViewRequestChanged = () => {
    const {history} = useSelector(state => state.RequestedCompanies)
    const { Title, Paragraph, Text, Link } = Typography;

    const userKeys = Object.keys(history.user_changed)
    const adminKeys = Object.keys(history.admin_changed)
    var filterKeys = userKeys.concat(adminKeys).filter((item, pos) => userKeys.concat(adminKeys).indexOf(item) === pos)

    const columns = [
        {
            title: 'Company Name',
            dataIndex: 'company_name',
            align: 'center'
        },
        {
            title: 'Address',
            dataIndex: 'address',
            align: 'center'
        },
        {
            title: 'Second Address',
            dataIndex: 'second_address',
            align: 'center'
        },
        {
            title: 'City',
            dataIndex: 'city',
            align: 'center'
        },
        {
            title: 'State Province',
            dataIndex: 'state_province',
            align: 'center'
        },
        {
            title: 'Tel',
            dataIndex: 'tel',
            align: 'center'
        },
        {
            title: 'Fax',
            dataIndex: 'fax',
            align: 'center'
        },
        {
            title: 'Zip Postal',
            dataIndex: 'zip_postal',
            align: 'center'
        },
        {
            title: 'Website',
            dataIndex: 'website',
            align: 'center'
        },
        {
            title: 'Previously Called',
            dataIndex: 'formerly',
            align: 'center'
        },
        {
            title: 'Owned By',
            dataIndex: 'subsidiary_of',
            align: 'center'
        },
    ];

    var filtered = columns.filter(function(item) {
        return filterKeys.indexOf(item.dataIndex) > -1;
    });
    filtered.unshift({title: 'Diff', dataIndex: 'diff', align: 'center', render(text, record) {
            return {
                props: {
                    style: { background:  "#ffffff"}
                },
                children: <Text strong>{text}</Text>
            };
        }}
    )
    history.original['diff'] = 'Original'
    history.user_changed['diff'] = 'User Change'
    history.admin_changed['diff'] = 'Admin Change'
    const data = [history.original, history.user_changed, history.admin_changed]
    return (
        <>
            <Table
                columns={filtered}
                pagination={false}
                dataSource={data}
                bordered
            />
            <Divider orientation="left">User Notes</Divider>
            <Paragraph title="User Note">
                <blockquote>{history?.user_note}</blockquote>
            </Paragraph>
            <Divider orientation="left">Admin Note</Divider>
            <Paragraph title="Admin Note">
                <blockquote>{history?.fm_note}</blockquote>
            </Paragraph>

        </>
    );
}

export default ViewRequestChanged;

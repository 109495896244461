import {CLEAR_REP_STATE, CREATE_REP_COMPANY, CREATE_REP_COMPANY_ERROR, CREATE_REP_COMPANY_SUCCESS} from "./actionTypes";


const initState = {
  representingCompanies: [],
  loading: false,
  formLoading: false,
  error: "",
  success: "",
};


const RepresentingCompanies = (state = initState, action) => {
    switch (action.type) {
      case CREATE_REP_COMPANY:
        return {
          ...state,
          formLoading: true
        };
      case CREATE_REP_COMPANY_SUCCESS:
        return {
          ...state,
          success: 'Request to view those companies sent',
          representingCompanies: [...state.representingCompanies, action.payload],
          formLoading: false
        };
      case CREATE_REP_COMPANY_ERROR:
        return {
          ...state,
          error: action.payload,
          formLoading: false,
        };
      case CLEAR_REP_STATE:
        return {
          ...state,
          error: "",
          success: "",
          loading: false,
          formLoading: false,
        };
        default:
            return state;
    }
}

export default RepresentingCompanies;

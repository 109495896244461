import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, Row, Select, Popconfirm, Spin, Typography} from "antd";
import basicStyle from "@iso/assets/styles/constants";
import {useDispatch, useSelector} from "react-redux";
import {editCompaniesRequested} from "../../../../redux/companies/companiesRequested/actions";

const RequestedCompanyForm = () => {
    const {requestedCompany, formLoading} = useSelector(state => state.RequestedCompanies)
    const {colStyle} = basicStyle;
    const [buttonDisable, setButtonDisable] = useState(true);
    const {Text} = Typography
    const { TextArea } = Input;
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields()
    }, [requestedCompany])

    const onFinish = (data) => {
        const formData = {
            address: (data.address) ? data.address : "",
            city: (data.city) ? data.city : "",
            company_name: (data.company_name) ? data.company_name : "",
            email: (data.email) ? data.email : "",
            fax: (data.fax) ? data.fax : "",
            notes: (data.notes) ? data.notes : "",
            second_address: (data.second_address) ? data.second_address : "",
            state_province: (data.state_province) ? data.state_province : "",
            tel: (data.tel) ? data.tel : "",
            website: (data.website) ? data.website : "",
            zip_postal: (data.zip_postal) ? data.zip_postal : "",
            formerly: (data.formerly) ? data.formerly : "",
            subsidiary_of: (data.subsidiary_of) ? data.subsidiary_of : ""
        }
        dispatch(editCompaniesRequested(formData, requestedCompany.id))
    }

    return (
        <Spin spinning={formLoading}>
        <Form
            id="edit_company_form"
            name="edit_company_form"
            layout="vertical"
            form={form}
            style={{width: "100%"}}
            onFinish={onFinish}
            initialValues={requestedCompany}
        >
            <Row gutter={24}>
                <Col md={24} sm={12} xs={24} style={{marginBottom: 0}}>
                    <Row gutter={24}>
                        <Col md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Company Name"
                                name="company_name"
                            >
                                <Input placeholder={requestedCompany?.history.original.company_name} name="company_name"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col md={12} sm={12} xs={12}>
                            <Form.Item
                                label="Address"
                                name="address"
                            >
                                <Input placeholder={requestedCompany?.history.original.address} name="address_one"/>
                            </Form.Item>
                        </Col>
                        <Col md={12} sm={12} xs={12}>
                            <Form.Item
                                label="Address 2"
                                name="second_address"
                            >
                                <Input placeholder={requestedCompany?.history.original.second_address} name="address_two"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                label="State/Province"
                                name="state_province"
                            >
                                <Input placeholder={requestedCompany?.history.original.state_province} name="state"/>
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            <Form.Item
                                label="City"
                                name="city"
                            >
                                <Input placeholder={requestedCompany?.history.original.city} name="city"/>
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={24}>
                            <Form.Item
                                label="Zip/Postal Code"
                                name="zip_postal"
                            >
                                <Input placeholder={requestedCompany?.history.original.zip_postal} name="zip"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col md={12} sm={12} xs={24}>
                            <Form.Item
                                label="Tel"
                                name="tel"
                            >
                                <Input placeholder={requestedCompany?.history.original.tel} name="tel"/>
                            </Form.Item>
                        </Col>
                        <Col md={12} sm={12} xs={24}>
                            <Form.Item
                                label="Fax"
                                name="fax"
                            >
                                <Input placeholder={requestedCompany?.history.original.fax} name="fax"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col md={12} sm={12} xs={12}>
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    {
                                        type: "email",
                                        message: "Please input a email address!",
                                    },
                                ]}
                            >
                                <Input placeholder="Company email" name="email"/>
                            </Form.Item>
                        </Col>
                        <Col md={12} sm={12} xs={12}>
                            <Form.Item
                                label="Website"
                                name="website"
                            >
                                <Input placeholder={requestedCompany?.history.original.website} name="website"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col md={12} sm={12} xs={24}>
                            <Form.Item
                                label="Previously Called"
                                name="formerly"
                            >
                                <Input placeholder="Previously Called" name="formerly"/>
                            </Form.Item>
                        </Col>
                        <Col md={12} sm={12} xs={24}>
                            <Form.Item
                                label="Owned By"
                                name="subsidiary_of"
                            >
                                <Input placeholder="Owned By" name="subsidiary_of"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Notes"
                                name="notes"
                            >
                                <TextArea rows={3}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div>
                        <Button form="edit_company_form" key="submit" htmlType="submit" type="primary" loading={formLoading} style={{float: "right"}}>Save Changes</Button>
                    </div>
                    <div style={{fontSize: "12px", marginTop: "12px"}}>
                        <Text>* placeholders contains the original company values</Text>
                    </div>
                </Col>
            </Row>
        </Form>
        </Spin>
    )

}

export default RequestedCompanyForm;

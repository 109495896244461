import {
    GET_REQUESTED_ACTIVE_INGREDIENTS,
    GET_REQUESTED_ACTIVE_INGREDIENTS_SUCCESS,
    SET_REQUESTED_ACTIVE_INGREDIENTS,
    GET_REQUESTED_AI_SYNONYMS,
    GET_REQUESTED_AI_SYNONYMS_SUCCESS,
    CLEAR_REQUESTED_ACTIVE_INGREDIENTS,
    GET_PAGINATION_REQUESTED_ACTIVE_INGREDIENTS,
    DATA_ERROR_REQUESTED_ACTIVE_INGREDIENTS,
    CLEAR_REQUESTED_ACTIVE_INGREDIENTS_STATE,
    NEW_REQUESTED_ACTIVE_INGREDIENTS,
    SET_ACTIVE_INGREDIENTS_REQUEST_HISTORY,
    DELETE_REQUESTED_ACTIVE_INGREDIENTS,
    DELETE_REQUESTED_ACTIVE_INGREDIENTS_SUCCESS,
    EDIT_REQUESTED_ACTIVE_INGREDIENTS,
    EDIT_REQUESTED_ACTIVE_INGREDIENTS_SUCCESS,
    EDIT_REQUESTED_ACTIVE_INGREDIENTS_FAIL,
    EDIT_LINK_REQUESTED_ACTIVE_INGREDIENTS,
    EDIT_LINK_REQUESTED_ACTIVE_INGREDIENTS_SUCCESS,
    EDIT_LINK_REQUESTED_ACTIVE_INGREDIENTS_FAIL
} from "./actionTypes";


const initialState = {
    requestedActiveIngredients: [],
    requestedSynonyms: [],
    requestedActiveIngredient: {},
    history: {},
    loading: false,
    formLoading: false,
    success: '',
    error: '',
    pagination: {
        current: 1,
        pageSize: 0,
        total: 0
    },
};

const RequestedActiveIngredients = (state = initialState, action) => {
    switch (action.type) {
        case GET_REQUESTED_ACTIVE_INGREDIENTS:
            return {
                ...state,
                loading: true
            };
        case GET_REQUESTED_ACTIVE_INGREDIENTS_SUCCESS:
            return {
                ...state,
                requestedActiveIngredients: action.payload,
                loading: false
            };
        case GET_REQUESTED_AI_SYNONYMS:
            return {
                ...state,
                formLoading: true
            };
        case GET_REQUESTED_AI_SYNONYMS_SUCCESS:
            return {
                ...state,
                requestedSynonyms: action.payload,
                formLoading: false
            };
        case SET_REQUESTED_ACTIVE_INGREDIENTS:
            return {
                ...state,
                requestedActiveIngredient: action.payload
            };
        case NEW_REQUESTED_ACTIVE_INGREDIENTS:
            return {
                ...state,
                requestedActiveIngredients: [...state.requestedActiveIngredients, action.payload],
            };
        case SET_ACTIVE_INGREDIENTS_REQUEST_HISTORY:
            return {
                ...state,
                history: action.payload
            };
        case EDIT_REQUESTED_ACTIVE_INGREDIENTS:
            return {
                ...state,
                formLoading: true
            };
        case EDIT_REQUESTED_ACTIVE_INGREDIENTS_SUCCESS:
            return {
                ...state,
                requestedActiveIngredients: state.requestedActiveIngredients.map(requestedCompany => requestedCompany.identifier === action.payload.identifier ?
                    action.payload :
                    requestedCompany),
                success: 'Request was successfully edited',
                formLoading: false
            };
        case EDIT_REQUESTED_ACTIVE_INGREDIENTS_FAIL:
            return {
                ...state,
                error: action.payload,
                formLoading: false
            };

        case EDIT_LINK_REQUESTED_ACTIVE_INGREDIENTS:
            return {
                ...state,
                formLoading: true
            };
        case EDIT_LINK_REQUESTED_ACTIVE_INGREDIENTS_SUCCESS:
            return {
                ...state,
                requestedActiveIngredients: state.requestedActiveIngredients.map(requestedCompany => requestedCompany.identifier === action.payload.identifier ?
                    action.payload :
                    requestedCompany),
                success: 'Request was successfully edited',
                formLoading: false
            };
        case EDIT_LINK_REQUESTED_ACTIVE_INGREDIENTS_FAIL:
            return {
                ...state,
                error: action.payload,
                formLoading: false
            };

        case DELETE_REQUESTED_ACTIVE_INGREDIENTS:
            return {
                ...state,
                loading: true
            };
        case DELETE_REQUESTED_ACTIVE_INGREDIENTS_SUCCESS:
            return {
                ...state,
                requestedActiveIngredients: state.requestedActiveIngredients.filter(requestedCompany => requestedCompany.identifier !== action.payload),
                success: 'Request was successfully deleted',
                loading: false
            };

        case DATA_ERROR_REQUESTED_ACTIVE_INGREDIENTS:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case GET_PAGINATION_REQUESTED_ACTIVE_INGREDIENTS:
            return {
                ...state,
                pagination: {
                    current: action.payload.current_page,
                    pageSize: action.payload.per_page,
                    total: action.payload.total
                },
            };
        case CLEAR_REQUESTED_ACTIVE_INGREDIENTS:
            return {
                ...state,
                activeIngredients: []
            };
        case CLEAR_REQUESTED_ACTIVE_INGREDIENTS_STATE:
            return {
                ...state,
                error: '',
                success: '',
                loading: false,
                formLoading: false
            };
        default:
            return state;
    }
}

export default RequestedActiveIngredients;

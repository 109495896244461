import React, {useEffect, useState} from "react";
import {Divider, Table, Typography} from "antd";
import {useSelector} from "react-redux";

const ViewActiveIngredientRequestChanged = () => {
    const {history} = useSelector(state => state.RequestedActiveIngredients)
    const {Text, Paragraph} = Typography;
    useEffect(() => {
      history.user_changes['diff'] = 'User Change';
      history.admin_change['diff'] = 'Admin Change'
    }, [history])
    const data = (Object.keys(history.admin_change).length > 0) ? [history.user_changes, history.admin_change] : [history.user_changes]
    const columns = [
        {
            title: 'Diff',
            dataIndex: 'diff',
            align: 'center',
        },
        {
            title: 'Active Ingredient Name',
            dataIndex: 'salt_name',
            align: 'center',
        },
        {
            title: 'Scale',
            dataIndex: 'web_scale',
            align: 'center',
        }
    ]
    return (
        <>
            <Table
                columns={columns}
                pagination={false}
                dataSource={data}
                bordered
            />
            {history.user_note  &&
            <><Divider orientation="left">User Notes</Divider>
                <Paragraph title="User Note">
                    <blockquote>{history?.user_note}</blockquote>
                </Paragraph>
            </>}
            {history.fm_note  &&
            <> <Divider orientation="left">Admin Note</Divider>
                <Paragraph title="Admin Note">
                    <blockquote>{history?.fm_note}</blockquote>
                </Paragraph>
            </>}
            {history.rejected_reason  &&
            <><Divider orientation="left">Rejected Reason</Divider>
            <Paragraph title="Rejected Reason">
                <blockquote>{history?.rejected_reason}</blockquote>
            </Paragraph>
            </>}
        </>
    );
}

export default ViewActiveIngredientRequestChanged

export const POST_LOGIN = "/login";
export const POST_REGISTER = "/register";
export const VERIFY_EMAIL_URL = "/verify-email";
export const RESEND_VERIFICATION_EMAIL = "/email/verification-notification";
export const PASSWORD_CHANGE = "/change-password";
export const FORGOT_PASSWORD = "/forgot-password";
export const PASSWORD_RESET = "/reset-password";
export const REP_COMPANIES = "/representing-companies";
export const GET_USER_STATE = "/user-state";
export const PROFILE = '/profile';

export const REPORT = '/report';

export const GET_MENU = "/menu";
export const GET_ROUTES = "/routes";

//USERS
export const USERS = '/users'
//Companies
export const COMPANIES = '/companies'
export const REQUESTED_COMPANIES = '/requested-companies'
export const CONTACTS = '/contacts'
export const REQUESTED_CONTACTS = '/requested-contacts'
export const ACTIVE_INGREDIENTS = '/active-ingredients'
export const REQUESTED_ACTIVE_INGREDIENTS = '/requested-active-ingredients'

import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import {
    createActiveIngredients,
    linkActiveIngredients,
    getActiveIngredients,
    getAiSynonyms,
    searchActiveIngredients,
    deleteActiveIngredient, createAIComment
} from '../../../library/http/backendHelpers';
import {
    CREATE_ACTIVE_INGREDIENTS, CREATE_ACTIVE_INGREDIENTS_COMMENTS, DELETE_ACTIVE_INGREDIENTS,
    GET_ACTIVE_INGREDIENTS,
    GET_AI_SYNONYMS,
    GET_SEARCH_ACTIVE_INGREDIENTS, LINK_ACTIVE_INGREDIENTS
} from "./actionTypes";
import {
    createActiveIngredientsCommentFail, createActiveIngredientsCommentSuccess,
    createActiveIngredientsFail,
    createActiveIngredientsSuccess,
    dataError, deleteActiveIngredientSuccess,
    getActiveIngredientsSuccess,
    getAiSynonymsSuccess, linkActiveIngredientsFail, linkActiveIngredientsSuccess, searchActiveIngredientsSuccess,
    setPagination
} from "./actions";
import {newRequestedActiveIngredient} from "../activeIngredientsRequested/actions";
import {setActiveIngredientNr} from "../../companies/companiesList/actions";

function* fetchActiveIngredients({payload: {company_kp, params}}) {
    try {
        const response = yield call(getActiveIngredients, company_kp, params);
        yield put(setPagination(response.meta))
        yield put(getActiveIngredientsSuccess(response.data));
    }catch (error) {
        yield put(dataError(error))
    }
}

function* searchActiveIngredientsSaga({payload: {company_kp, params}}) {
    try {
        const response = yield call(searchActiveIngredients, company_kp, params);
        yield put(searchActiveIngredientsSuccess(response.data));
    }catch (error) {
        yield put(dataError(error))
    }
}

function* fetchActiveIngredientSynonyms({payload: { company_kp, ai_id, params }}) {
    try {
        const response = yield call(getAiSynonyms, company_kp, ai_id, params);
        yield put(getAiSynonymsSuccess(response.data));
    }catch (error) {
        yield put(dataError(error))
    }
}

function* createActiveIngredient({ payload: {activeIngredient, company_identifier} }) {
    try {
        const response = yield call(createActiveIngredients, activeIngredient, company_identifier);
        yield put(createActiveIngredientsSuccess(response.data));
        yield put(newRequestedActiveIngredient(response.data))
        yield put(setActiveIngredientNr())
    }catch (error) {
        yield put(createActiveIngredientsFail(error))
    }
}

function* linkActiveIngredient({ payload: {activeIngredient, company_identifier} }) {
    try {
        const response = yield call(linkActiveIngredients, activeIngredient, company_identifier);
        yield put(linkActiveIngredientsSuccess(response.data));
        yield put(newRequestedActiveIngredient(response.data))
        yield put(setActiveIngredientNr())
    }catch (error) {
        yield put(linkActiveIngredientsFail(error))
    }
}

function* deleteActiveIngredientSaga({payload: { company_id, ai_id, reason, identifier_linkage }}) {
    try {
        const response = yield call(deleteActiveIngredient, company_id, ai_id, {rejected_reason: reason, identifier_linkage: identifier_linkage});
        yield put(deleteActiveIngredientSuccess(response.data));
        yield put(newRequestedActiveIngredient(response.data))
        yield put(setActiveIngredientNr())
    }catch (error) {
        yield put(dataError(error))
    }
}

function* createActiveIngredientComment({ payload: {identifier, comment}}) {
    try {
        const response = yield call(createAIComment, identifier, comment);
        yield put(createActiveIngredientsCommentSuccess(identifier, comment));
    }catch (error) {
        yield put(createActiveIngredientsCommentFail(error))
    }
}

function* activeIngredientsSaga() {
    yield takeEvery(GET_ACTIVE_INGREDIENTS, fetchActiveIngredients);
    yield takeEvery(GET_AI_SYNONYMS, fetchActiveIngredientSynonyms);
    yield takeEvery(CREATE_ACTIVE_INGREDIENTS, createActiveIngredient);
    yield takeEvery(GET_SEARCH_ACTIVE_INGREDIENTS, searchActiveIngredientsSaga);
    yield takeEvery(LINK_ACTIVE_INGREDIENTS, linkActiveIngredient);
    yield takeEvery(DELETE_ACTIVE_INGREDIENTS, deleteActiveIngredientSaga);
    yield takeEvery(CREATE_ACTIVE_INGREDIENTS_COMMENTS, createActiveIngredientComment);
}

export default activeIngredientsSaga

import {
    CLEAR_REQUESTED_COMPANIES_STATE,
    CREATE_REQUESTED_COMPANIES,
    CREATE_REQUESTED_COMPANIES_FAIL,
    CREATE_REQUESTED_COMPANIES_SUCCESS,
    DATA_ERROR_REQUESTED_COMPANIES, DELETE_REQUESTED_COMPANIES, DELETE_REQUESTED_COMPANIES_SUCCESS,
    EDIT_REQUESTED_COMPANIES,
    EDIT_REQUESTED_COMPANIES_FAIL,
    EDIT_REQUESTED_COMPANIES_SUCCESS,
    GET_PAGINATION_REQUESTED_COMPANIES,
    GET_REQUESTED_COMPANIES,
    GET_REQUESTED_COMPANIES_SUCCESS,
    SET_EDIT_COMPANY,
    SET_REQUEST_HISTORY,
} from "./actionTypes";

const initialState = {
    requestedCompanies: [],
    requestedCompany: {},
    history: {},
    loading: false,
    formLoading: false,
    success: '',
    error: '',
    pagination: {
        current: 1,
        pageSize: 0,
        total: 0
    },
};

const RequestedCompanies = (state = initialState, action) => {
    switch (action.type) {
        case GET_REQUESTED_COMPANIES:
            return {
                ...state,
                loading: true
            };
        case GET_REQUESTED_COMPANIES_SUCCESS:
            return {
                ...state,
                requestedCompanies: action.payload,
                loading: false
            };
        case SET_EDIT_COMPANY:
            return {
                ...state,
                requestedCompany: action.payload
            };
        case SET_REQUEST_HISTORY:
            return {
                ...state,
                history: action.payload
            };
        case CREATE_REQUESTED_COMPANIES:
            return {
                ...state,
                formLoading: true
            };
        case CREATE_REQUESTED_COMPANIES_SUCCESS:
            return {
                ...state,
                success: 'Company Request was successfully sent',
                requestedCompanies: [action.payload, ...state.requestedCompanies],
                formLoading: false
            };
        case CREATE_REQUESTED_COMPANIES_FAIL:
            return {
                ...state,
                error: action.payload,
                formLoading: false,
            };
        case EDIT_REQUESTED_COMPANIES:
            return {
                ...state,
                formLoading: true
            };
        case EDIT_REQUESTED_COMPANIES_SUCCESS:
            return {
                ...state,
                requestedCompanies: state.requestedCompanies.map(requestedCompany => requestedCompany.id === action.payload.id ?
                    action.payload :
                    requestedCompany),
                success: 'Request was successfully edited',
                formLoading: false
            };
        case EDIT_REQUESTED_COMPANIES_FAIL:
            return {
                ...state,
                error: action.payload,
                formLoading: false
            };
        case DELETE_REQUESTED_COMPANIES:
            return {
                ...state,
                loading: true
            };
        case DELETE_REQUESTED_COMPANIES_SUCCESS:
            return {
                ...state,
                requestedCompanies: state.requestedCompanies.filter(requestedCompany => requestedCompany.id !== action.payload),
                success: 'Request was successfully deleted',
                loading: false
            };
        case DATA_ERROR_REQUESTED_COMPANIES:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case GET_PAGINATION_REQUESTED_COMPANIES:
            return {
                ...state,
                pagination: {
                    current: action.payload.current_page,
                    pageSize: action.payload.per_page,
                    total: action.payload.total
                },
            };
        case CLEAR_REQUESTED_COMPANIES_STATE:
            return {
                ...state,
                error: '',
                success: '',
                loading: false,
                formLoading: false
            };
        default:
            return state;
    }
}

export default RequestedCompanies;

import React, {useEffect, useState} from "react";
import {Col, Row, Table, Typography} from "antd";
import {useSelector} from "react-redux";
import basicStyle from "@iso/assets/styles/constants";

const ViewChangedFiles = () => {
    const {Text} = Typography;
    const {history} = useSelector(state => state.RequestedCompanies)
    const objects = Object.keys(history?.user_changed)
    const items = [];
    const color = history.request_status === 'new' ? '#91d5ff' :
        history.request_status === 'in_review' ? '#ffd591' :
            history.request_status === 'accepted' ? '#b7eb8f' : '#ffa39e'
    const style = { border: "1px solid ", borderColor: color, padding: '1px 5px' };

    const  humanize = (str) => {
        var i, frags = str.split('_');
        for (i=0; i<frags.length; i++) {
            frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        return frags.join(' ');
    }

    objects.forEach(key => {
        items.push(
            <Col className="gutter-row">
                <div style={{...style, fontSize: "13px"}}>
                    <div><Text strong>{humanize(key)}:</Text></div>
                    <Text type="secondary">{history.user_changed[key]}</Text>
                </div>
            </Col>
        )
    })

    return (
        <div>
            <Row gutter={[16, 8]}>
                {items}
            </Row>
        </div>
    )
}

export default ViewChangedFiles

import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, Row, Select, Spin, Typography, Divider, Modal} from "antd";
import {useDispatch, useSelector} from "react-redux";
import { ArrowLeftOutlined } from '@ant-design/icons';
import basicStyle from "@iso/assets/styles/constants";
import {createActiveIngredients, clearState} from "../../../../../redux/activeIngredients/activeIngredientsList/actions";
import {showNotification} from "../../../../../redux/notification/actions";
import {closeModalMain, openModalMain} from "../../../../../redux/rootModal/actions";

const AddActiveIngredientForm = () => {
    const {colStyle} = basicStyle;
    const {Text} = Typography
    const {TextArea} = Input;
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const {company} = useSelector(state => state.Companies)
    const {formLoading, success, error} = useSelector(state => state.ActiveIngredients)

    useEffect(() => {
        form.resetFields()
    }, [])

    const onFinish = (data) => {
        if (company?.identifier) {
            const formData = {
                salt_name: (data.salt_name) ? data.salt_name : '',
                notes: (data.notes) ? data.notes : '',
                web_scale: (data.web_scale) ? data.web_scale : '',
            }
           dispatch(createActiveIngredients(formData,company.identifier))
        }
    }

    const notification = (type, message) => {
        dispatch(showNotification({ type: type, title: message }))
    };

    if (success !== "") {
        notification("success", success);
        dispatch(closeModalMain());
        dispatch(clearState());
    }
    if (error !== "") {
        if (error === 'Company already has this Active Ingredient' || error === 'This ingredient has already been added/requested'){
            Modal.error({
                title: error,
            });
            dispatch(clearState());
        }else {
            notification("error", error);
            dispatch(clearState());
        }
    }

    const openLinkAiModal = () => {
        let props = {
            modalVisibility: true,
            modalType: "LINK_AI_FORM",
            modalProps: {
                title: "Add New Active Ingredient ",
                width: 600,
                maskClosable: false,
                style: { top: "30px" },
                footer: "",
            },
        };
        dispatch(openModalMain(props));
    }


    return (
        <Spin spinning={formLoading}>
            <Form
                id="add_ai_form"
                name="add_ai_form"
                layout="vertical"
                form={form}
                style={{width: "100%"}}
                onFinish={onFinish}
                initialValues={[]}
            >
                <Row gutter={24}>
                    <Col md={24} sm={12} xs={24} style={{marginBottom: 0}}>
                        <Row gutter={24}>
                            <Col md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Active Ingredient Name"
                                    name="salt_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input active ingredient name!",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Active Ingredient Name" name="salt_name"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Scale"
                                    name="web_scale"
                                >
                                    <Input placeholder="Scale" name="scale"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Comments"
                                    name="notes"
                                >
                                    <TextArea rows={6}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <div>
                            <Button loading={formLoading} style={{float: "left"}} onClick={openLinkAiModal}><ArrowLeftOutlined/>Back</Button>
                            <Button form="add_ai_form" key="submit" htmlType="submit" type="primary" loading={formLoading} style={{float: "right"}}>Add Active Ingredient</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Spin>
    )
}

export default AddActiveIngredientForm;

export const GET_CONTACTS = "GET_CONTACTS";
export const GET_CONTACTS_SUCCESS = "GET_CONTACTS_SUCCESS";

export const CREATE_CONTACT = "CREATE_CONTACT";
export const CREATE_CONTACT_SUCCESS = "CREATE_CONTACT_SUCCESS";
export const CREATE_CONTACT_FAIL = "CREATE_USER_FAIL";

export const EDIT_CONTACT = "EDIT_CONTACT";
export const EDIT_CONTACT_SUCCESS = "EDIT_CONTACT_SUCCESS";
export const EDIT_CONTACT_FAIL = "EDIT_CONTACT_FAIL";

export const DELETE_CONTACTS = "DELETE_CONTACTS";
export const DELETE_CONTACTS_SUCCESS = "DELETE_CONTACTS_SUCCESS";

export const CLEAR_CONTACTS= "CLEAR_CONTACTS";
export const SET_CONTACT = "SET_CONTACT";

export const GET_PAGINATION_CONTACTS = "GET_PAGINATION_CONTACTS";
export const DATA_ERROR_CONTACTS = "DATA_ERROR_CONTACTS";
export const CLEAR_CONTACTS_STATE = "CLEAR_CONTACTS_STATE";

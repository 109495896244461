import {
    CLEAR_REQUESTED_CONTACTS_STATE,
    DATA_ERROR_REQUESTED_CONTACTS,
    GET_REQUESTED_CONTACTS,
    GET_REQUESTED_CONTACTS_SUCCESS,
    GET_PAGINATION_REQUESTED_CONTACTS,
    NEW_REQUESTED_CONTACT,
    SET_CONTACT_REQUEST_HISTORY,
    SET_CONTACT_REQUEST,
    EDIT_REQUESTED_CONTACT,
    EDIT_REQUESTED_CONTACT_SUCCESS,
    EDIT_REQUESTED_CONTACT_FAIL, DELETE_REQUESTED_CONTACTS, DELETE_REQUESTED_CONTACTS_SUCCESS
} from "./actionTypes";
import {
    DELETE_CONTACTS,
    DELETE_CONTACTS_SUCCESS,
    EDIT_CONTACT,
    EDIT_CONTACT_FAIL,
    EDIT_CONTACT_SUCCESS
} from "../contactList/actionTypes";

const initialState = {
    requestedContacts: [],
    requestedContact: {},
    history: {},
    loading: false,
    formLoading: false,
    success: '',
    error: '',
    pagination: {
        current: 1,
        pageSize: 0,
        total: 0
    },
};

const RequestedContacts = (state = initialState, action) => {
    switch (action.type) {
        case GET_REQUESTED_CONTACTS:
            return {
                ...state,
                loading: true
            };
        case GET_REQUESTED_CONTACTS_SUCCESS:
            return {
                ...state,
                requestedContacts: action.payload,
                loading: false
            };
        case DATA_ERROR_REQUESTED_CONTACTS:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case NEW_REQUESTED_CONTACT:
            return {
                ...state,
                requestedContacts: [...state.requestedContacts, action.payload],
            };
        case SET_CONTACT_REQUEST_HISTORY:
            return {
                ...state,
                history: action.payload
            };
        case SET_CONTACT_REQUEST:
            return {
                ...state,
                requestedContact: action.payload
            };
        case EDIT_REQUESTED_CONTACT:
            return {
                ...state,
                formLoading: true
            };
        case EDIT_REQUESTED_CONTACT_SUCCESS:
            return {
                ...state,
                success: 'Requested Contact was successfully edited',
                requestedContacts: state.requestedContacts.map((contact) => contact.identifier === action.payload.identifier ? action.payload : contact),
                formLoading: false
            };
        case EDIT_REQUESTED_CONTACT_FAIL:
            return {
                ...state,
                error: action.payload,
                formLoading: false
            };
        case DELETE_REQUESTED_CONTACTS:
            return {
                ...state,
                loading: true
            };
        case DELETE_REQUESTED_CONTACTS_SUCCESS:
            return {
                ...state,
                requestedContacts: state.requestedContacts.filter(requestedCompany => requestedCompany.identifier !== action.payload),
                success: 'Requested Contact was successfully deleted',
                loading: false
            };
        case GET_PAGINATION_REQUESTED_CONTACTS:
            return {
                ...state,
                pagination: {
                    current: action.payload.current_page,
                    pageSize: action.payload.per_page,
                    total: action.payload.total
                },
            };
        case CLEAR_REQUESTED_CONTACTS_STATE:
            return {
                ...state,
                error: '',
                success: '',
                loading: false,
                formLoading: false
            };
        default:
            return state;
    }
}

export default RequestedContacts;
